import { useMsal } from "@azure/msal-react";
import { AlertColor, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { put } from "../../ajax";
import { useAuth } from "../../contexts/AuthContext";
import { dateConverter, getDayFromDate } from "../../hooks/DateConverter";
import { BillingInterval } from "../../models/BillingInterval";
import { Rate } from "../../models/Rate";
import { RateHistory } from "../../models/RateHistory";
import { RatePutResponse } from "../../models/RatePutResponse";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";

interface Props {
  rateHistory: RateHistory;
  serviceType: ServiceType;
  serviceItem: ServiceItem;
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setSeverity: React.Dispatch<React.SetStateAction<AlertColor | undefined>>;
}

/**
 * TableRow in serviceItem history with option to reset to clicked rate for table row
 * @param {RateHistory} rateHistory Rate history from chosen ServiceItem
 * @param {ServiceType} serviceType Chosen serviceType
 * @param {ServiceItem} serviceItem Chosen serviceItem
 * @param {ServiceType[]} serviceTypes All serviceTypes
 * @param {SetStateAction} setServiceTypes Setter serviceTypes
 * @param {SetStateAction} setSuccess Setter for boolean in SucessAlert
 * @param {SetStateAction} setSeverity Setter for AlertColor in SucessAlert
 * @returns RateHistoryCard
 */
const RateHistoryCard = ({
  rateHistory,
  serviceType,
  serviceItem,
  serviceTypes,
  setServiceTypes,
  setSuccess,
  setSeverity,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const { accounts } = useMsal();
  const email = accounts[0].username;
  const { tags } = useAuth();

  const setRateToClickedRow = () => {
    const putItem = {
      rateId: rateHistory.rateId,
      price: rateHistory.toPrice,
      cogs: rateHistory.toCogs,
      priceUnit: rateHistory.toPriceUnit,
      currency: rateHistory.toCurrency,
      billingInterval: rateHistory.toBillingInterval,
      reason: "Reset to " + dateConverter(rateHistory.dateChanged),
      userEmail: email,
      exivityId: serviceItem.rate.exivityId,
      serviceItemExivityId: serviceItem.exivityId,
    };
    setOpenLoadingModal(true);
    put("/ServiceType/ServiceItem/Rate", putItem)
      .then((response) => {
        setOpenLoadingModal(false);
        const serviceRateResponse: RatePutResponse = response.data;
        const newRateHistoryItem: RateHistory = {
          rateHistoryId: "temp",
          rateId: serviceRateResponse.rateId,
          dateChanged: serviceRateResponse.date,
          toPrice: serviceRateResponse.price,
          toCogs: serviceRateResponse.cogs,
          toPriceUnit: serviceRateResponse.priceUnit,
          toCurrency: serviceRateResponse.currency,
          toBillingInterval: serviceRateResponse.billingInterval,
          reason: "Reset to " + dateConverter(rateHistory.dateChanged),
          userEmail: email,
        };
        const serviceTypesList: ServiceType[] = serviceTypes;
        const typeIndex = serviceTypesList.findIndex(
          (obj) => obj.serviceTypeId == serviceType.serviceTypeId
        );
        const itemIndex = serviceTypesList[typeIndex].serviceItems.findIndex(
          (obj) => obj.serviceItemId == serviceItem.serviceItemId
        );
        const oldRateHistory: RateHistory[] =
          serviceTypesList[typeIndex].serviceItems[itemIndex].rate.rateHistory;
        oldRateHistory.push(newRateHistoryItem);

        const serviceRateObj: Rate = {
          exivityId: serviceRateResponse.exivityId,
          billingInterval: serviceRateResponse.billingInterval,
          cogs: serviceRateResponse.cogs,
          currency: serviceRateResponse.currency,
          price: serviceRateResponse.price,
          priceUnit: serviceRateResponse.priceUnit,
          rateHistory: oldRateHistory,
          rateId: serviceRateResponse.rateId,
        };

        serviceTypesList[typeIndex].serviceItems[itemIndex].rate =
          serviceRateObj;
        setServiceTypes(serviceTypesList);

        setSuccess(true);
        setSeverity("success");
      })
      .catch(() => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("error");
      });
  };

  const handleRowClick = () => {
    setSuccess(false);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <LoadingModal text="Updating Rate" open={openLoadingModal} />
      <TableRow
        title={
          tags.findIndex((tag) => tag.scope == "rate:put") == -1
            ? "No permission to reset rate"
            : "Click to reset to rate"
        }
        onClick={() => {
          if (tags.findIndex((tag) => tag.scope == "rate:put") > -1) {
            handleRowClick();
          }
        }}
        hover={tags.findIndex((tag) => tag.scope == "rate:put") > -1}
        selected={tags.findIndex((tag) => tag.scope == "rate:put") > -1}
      >
        <TableCell>{rateHistory.reason}</TableCell>
        <TableCell>{BillingInterval[rateHistory.toBillingInterval]}</TableCell>
        <TableCell>{rateHistory.toCogs}</TableCell>
        <TableCell>{rateHistory.toCurrency}</TableCell>
        <TableCell>{rateHistory.toPrice}</TableCell>
        <TableCell>{rateHistory.toPriceUnit}</TableCell>
        <TableCell>
          {getDayFromDate(rateHistory.dateChanged) +
            dateConverter(rateHistory.dateChanged)}
        </TableCell>
        <TableCell>{rateHistory.userEmail}</TableCell>
      </TableRow>

      <ProceedDialog
        text={"Are you sure you want to reset the rate to this?"}
        open={open}
        setOpen={setOpen}
        onProceedAction={setRateToClickedRow}
      ></ProceedDialog>
    </React.Fragment>
  );
};

export default RateHistoryCard;
