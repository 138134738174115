import { TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { Report } from "../../models/Report";

interface Props {
  report: Report[];
}

interface Data {
  serviceTypeName: string;
  totalCharge: number;
  totalCogs: number;
  totalNet: number;
}

type Order = "asc" | "desc";

/**
 * Component for information table in Dashboard 
 * @param {report[]} report Information from exivity. Used for information in the table
 * @returns DashboarDetaild
 */
const DashboardDetailed = ({ report }: Props) => {
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("totalNet");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const NOK = Intl.NumberFormat('no-NO')


    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    }

    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const formatNumber = (num: number) => {
    return NOK.format(num)
}

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === "serviceTypeName" ? order : false}>
              <TableSortLabel
                active={orderBy === "serviceTypeName"}
                direction={orderBy === "serviceTypeName" ? order : "asc"}
                onClick={createSortHandler("serviceTypeName")}
              >
                SERVICE
                {orderBy === "serviceTypeName" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={orderBy === "totalCharge"}
                direction={orderBy === "totalCharge" ? order : "asc"}
                onClick={createSortHandler("totalCharge")}
              >
                CHARGE
                {orderBy === "totalCharge" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={orderBy === "totalCogs"}
                direction={orderBy === "totalCogs" ? order : "asc"}
                onClick={createSortHandler("totalCogs")}
              >
                COGS
                {orderBy === "totalCogs" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={orderBy === "totalNet"}
                direction={orderBy === "totalNet" ? order : "asc"}
                onClick={createSortHandler("totalNet")}
              >
                NET
                {orderBy === "totalNet" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {report
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((rep: Report) => (
              <TableRow key={rep.serviceTypeName}>
                <TableCell component="th" scope="row">
                  {rep.serviceTypeName}
                </TableCell>
                <TableCell align="right">
                  NOK {formatNumber(rep.totalCharge)}
                </TableCell>
                <TableCell align="right">
                  NOK {formatNumber(rep.totalCogs)}
                </TableCell>
                <TableCell align="right">
                  NOK {formatNumber(rep.totalNet)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={report.length > 5 ? [5, report.length] : [report.length]}
        component="div"
        count={report.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default DashboardDetailed;
