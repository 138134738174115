import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { SyncLoader } from "react-spinners";

interface Props {
  text: string;
  open: boolean;
}
/**
 * Modal used when waiting for api
 * @param {string } text string displayed in modal when open
 * @param {boolean } open boolean to open and close modal
 * @returns LoadingModal
 */
const LoadingModal = ({ open, text }: Props) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: "15vw",
        }}
      >
        <SyncLoader color="#9edebd" size={40}></SyncLoader>
        <Typography variant="h4" sx={{ marginTop: "5%", color: "#9edebd" }}>
          {text}
        </Typography>
      </Box>
    </Modal>
  );
};

/**
 * kjoor
 */
export default LoadingModal;
