import { useState } from "react";
// useForm functional componen
interface init {
  reason: string;
  name: string;
  description: string;
  serviceTypeId: string | undefined;
  price: number;
  cogs: number;
  priceUnit: string;
  currency: string;
  billingInterval: number;
  userEmail?: string;
}
export const useForm = (
  callback: any,
  initialState: init = {
    reason: "",
    name: "",
    description: "",
    serviceTypeId: undefined,
    price: 0,
    cogs: 0,
    priceUnit: "",
    currency: "",
    billingInterval: 7,
  }
) => {
  const [values, setValues] = useState<init>(initialState);
  const intervalOptions = [
    "NEVER",
    "INDUVIDUALLY",
    "SECOND",
    "MINUTE",
    "HOUR",
    "DAY",
    "WEEK",
    "MONTH",
    "YEAR",
  ];

  // onChange
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (intervalOptions.includes(event.target.value)) {
      event.target.value = intervalOptions
        .indexOf(event.target.value)
        .toString();
    }

    setValues({ ...values, [event.target.name]: event.target.value });
  };

  // onSubmit
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await callback(); // triggering the callback
  };

  // return values
  return {
    onChange,
    onSubmit,
    values,
  };
};
