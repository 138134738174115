import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FeedIcon from "@mui/icons-material/Feed";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import UpdateIcon from "@mui/icons-material/Update";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import PageHeader from "../components/PageComponents/PageHeader";
import ServiceContacts from "../components/ServiceComponents/ServiceContacts";
import ServiceInformation from "../components/ServiceComponents/ServiceInformation";
import ServiceItemHistory from "../components/ServiceComponents/ServiceItemHistory";
import { ServiceItem } from "../models/ServiceItem";
import { ServiceType } from "../models/ServiceType";

interface Props {
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}

/**
 * Container for all serviceItem related information
 * @param {ServiceType[]} serviceTypes list of all servicetypes
 * @param {SetStateAction} setServiceTypes setter for all servicetypes
 * @returns ServiceItemContainer or packmanloader
 */
const ServiceItemContainer = ({ serviceTypes, setServiceTypes }: Props) => {
  const location = useLocation();
  let urlArr = location.pathname.split("/");
  let id = urlArr[3];
  let serviceItemName = urlArr[4].replaceAll("%20", " ");
  const [tabValue, setTabValue] = useState(0);

  //To rerender when new url is pushed
  useEffect(() => {
    urlArr = location.pathname.split("/");
    id = urlArr[3];
    serviceItemName = urlArr[3];
  }, [location]);

  const serviceTypeFromUrl = serviceTypes.find((serviceType: ServiceType) => {
    return serviceType.serviceTypeId === id;
  });

  const serviceItemFromUrl = serviceTypeFromUrl?.serviceItems.find(
    (serviceItem: ServiceItem) => {
      return serviceItem.name === serviceItemName;
    }
  );

  if (serviceItemFromUrl == undefined || serviceTypeFromUrl == undefined) {
    return (
      <>
        <PageHeader
          title="Loading Service Item"
          subtitle="Information, History, Contacts"
          icon={<FeedIcon />}
        ></PageHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "15vw",
          }}
        >
          <PacmanLoader size={40}></PacmanLoader>
        </Box>
      </>
    );
  }

  /**
   * Get chosen tab and
   * @returns chosen component Information, History or Contacts
   */
  const getTab = () => {
    if (tabValue === 0) {
      return (
        <ServiceInformation
          serviceTypes={serviceTypes}
          setServiceTypes={setServiceTypes}
          serviceType={serviceTypeFromUrl}
          serviceItem={serviceItemFromUrl}
        />
      );
    } else if (tabValue === 1) {
      return (
        <ServiceItemHistory
          serviceType={serviceTypeFromUrl}
          serviceTypes={serviceTypes}
          setServiceTypes={setServiceTypes}
          serviceItem={serviceItemFromUrl}
        />
      );
    } else {
      return (
        <ServiceContacts
          serviceType={serviceTypeFromUrl}
          serviceTypes={serviceTypes}
          setServiceTypes={setServiceTypes}
        />
      );
    }
  };

  return (
    <>
      <PageHeader
        title={serviceItemFromUrl.name}
        subtitle="Information, History, Contacts"
        icon={<FeedIcon />}
      ></PageHeader>
      <Paper elevation={4}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            sx={{ color: "03DAC6" }}
            variant="fullWidth"
            centered={true}
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="tabs for choosing what inforamtion to display"
          >
            <Tab
              value={0}
              label="Information"
              icon={<ImportContactsIcon />}
              iconPosition="end"
            ></Tab>
            <Tab
              value={1}
              label="History"
              icon={<UpdateIcon />}
              iconPosition="end"
            ></Tab>
            <Tab
              value={2}
              label="Contacts and links"
              icon={<AlternateEmailIcon />}
              iconPosition="end"
            ></Tab>
          </Tabs>
        </Box>
        {getTab()}
      </Paper>
    </>
  );
};

export default ServiceItemContainer;
