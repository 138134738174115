import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Box,
  Divider,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { dateConverter } from "../../hooks/DateConverter";

import { Offer } from "../../models/Offer";

interface Props {
  offer: Offer;
}

/**
 * Clickable component for displaying offers in OfferContainer
 * @param {Offer} offer Used to display information from the offer in the list
 * @returns Clickable listitem with offer inforamtion and navigation to related Offer
 */
const OfferCard = ({ offer }: Props) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ width: "100%" }}>
        <ListItemButton
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
          alignItems="center"
          onClick={() =>
            navigate(`/offer/${offer.offerId}`, {
              state: {
                offer: offer,
              },
            })
          }
        >
          <LocalOfferIcon />
          <ListItemText primary={offer.name}></ListItemText>
          <Typography>
            {" Last updated: (" + dateConverter(offer.lastUpdated) + ")"}{" "}
          </Typography>
        </ListItemButton>
        <Divider />
      </Box>
    </Box>
  );
};

export default OfferCard;
