import { AccountInfo } from "@azure/msal-common/dist/account/AccountInfo";
import { Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/NavbarComponents/Navbar";
import Sidebar from "../components/SidebarComponents/Sidebar";

interface Props {
  isDarkMode: boolean;
  updateDarkmode: (bool: boolean) => void;
  showSidebar: boolean;
  setShowSidebar: (bool: boolean) => void;
  accounts: AccountInfo[];
}

/**
 * Container for elements that shows on any page
 * @returns Layout
 */

const Layout = ({
  isDarkMode,
  updateDarkmode,
  showSidebar,
  setShowSidebar,
  accounts,
}: Props) => {
  const SlideContent = styled("main", {
    shouldForwardProp: (prop) => prop !== "showSidebar",
  })<{
    showSidebar?: boolean;
  }>(({ theme, showSidebar }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `220px`,
    ...(!showSidebar && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }));
  return (
    <div>
      <Navbar
        isDarkMode={isDarkMode}
        updateDarkmode={updateDarkmode}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        accounts={accounts}
      ></Navbar>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      ></Sidebar>
      <SlideContent showSidebar={showSidebar}>
        <Toolbar />
        <Outlet />
      </SlideContent>
    </div>
  );
};

export default Layout;
