import axios from "axios";

axios.defaults.baseURL = "https://apc.norwayeast.cloudapp.azure.com/api/api";
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const post = async (url: string, body: any) => {
  return axios.post(url, body);
};

export const get = (url: string) => {
  return axios.get(url);
};

export const remove = (url: string) => {
  return axios.delete(url);
};

export const put = (url: string, body: any) => {
  return axios.put(url, body);
};
