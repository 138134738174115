import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import logo from "../assets/bilder/AteaPng2.png";

const generatePDF = (
  serviceTypes,
  offerName,
  account
) => {
  const pdftable = [];

  serviceTypes.forEach((serviceType) => {
    serviceType.serviceItems.forEach((item) => {
      pdftable.push([
        serviceType.friendlyName,
        item.name,
        item.rate.price,
        item.rate.currency,
        item.rate.priceUnit,
      ]);
    });
  });
  let total = 0;
  pdftable.forEach((el) => {
    total = total + el[4];
  });
  const stringTotal = total.toString();

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;

  var props = {
    outputType: OutputType.Save,
    returnJsPDFDocObject: true,
    fileName: offerName,
    orientationLandscape: false,
    compress: true,
    logo: {
      src: logo,
      width: 30, //aspect ratio = width/height
      height: 10,
      margin: {
        top: 0, //negative or positive num, from the current position
        left: 0, //negative or positive num, from the current position
      },
    },
    business: {
      name: "Atea Managed Services",
      email: `Made by: ${account} `,
    },
    contact: {
      label: "Offer name:",
      name: offerName,
    },
    invoice: {
      label: "",
      num: "",
      invDate: "Creation Date: " + today,
      headerBorder: false,
      tableBodyBorder: false,
      header: [
        {
          title: "ServiceType",
          style: {
            width: 55,
          },
        },
        {
          title: "ServiceItem",
          style: {
            width: 55,
          },
        },
        { title: "Price" },
        { title: "Unit" },
        { title: "Total" },
      ],
      table: pdftable,
    },
    footer: {
      text: "",
    },
    pageEnable: true,
    pageLabel: "Page ",
  };
  var pdfObject = jsPDFInvoiceTemplate(props);
};

export default generatePDF;
