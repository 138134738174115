import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box, Card, Grid } from '@mui/material';
import { AxiosResponse } from 'axios';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { PacmanLoader } from 'react-spinners';
import { get } from '../ajax';
import DashboardSummary from '../components/DashboardComponents/DashboardSummary';
import PageHeader from '../components/PageComponents/PageHeader';
import ReportHeader from '../components/Reports/ReportHeader';
import ReportLine from '../components/Reports/ReportLine';
import ReportPie from '../components/Reports/ReportPie';
import ServiceItemDetailed from '../components/Reports/ServiceItemDetailed';
import ServiceTypeDetailed from '../components/Reports/ServiceTypeDetailed';
import useWindowSize from '../hooks/useWindowSize';
import { ExivityAccount } from '../models/ExivityAccount';
import { ExivityServiceCategory } from '../models/ExivityServiceCategory';
import { Report } from '../models/Report';
import { backgroundColors} from '../colors';

ChartJS.register(ArcElement, PointElement,
    LineElement, Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement,
);

type lineDataInterface = {
    label: string,
    data: number[];
    borderColor: string;
    backgroundColor: string;
}

function isLineData(obj: any): obj is lineDataInterface[] {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        'label' in obj[0] &&
        'data' in obj[0]
    );
}

/**
 * Container for all report components
 * @returns Reports
 */

const Reports = () => {
    const [toDate, setToDate] = React.useState<Date | null>(new Date());
    const [fromDate, setFromDate] = React.useState<Date | null>(new Date(Date.now() - 31 * 24 * 60 * 60 * 1000));
    const [exivityAccounts, setExivityAccounts] = useState<ExivityAccount[]>([]);
    const [exivityServiceCategories, setExivityServiceCategories] = useState<ExivityServiceCategory[]>([]);
    const [selectedExivityServiceCategory, setSelectedExivityServiceCategory] = React.useState<ExivityServiceCategory | undefined>(undefined);
    const [selectedAccount, setSelectedAccount] = React.useState<ExivityAccount | undefined>(undefined);
    const [report, setReport] = useState<Report[]>([]);
    const [profits, setProfits] = useState<number[]>([]);
    const [serviceNames, setServiceNames] = useState<string[]>([]);
    const [lineData, setLineData] = useState<lineDataInterface[]>([]);
    const [timeLabels, setTimeLabels] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [noArguments, setNoArguments] = useState<boolean>(true);
    const { width } = useWindowSize();


    const getReportsWithNoArguments = async (exivitySerCategories: ExivityServiceCategory[]) => {
        setLoading(true)
        await get(`/Report/Service/${fromDate?.toLocaleDateString('en-GB').split('/').reverse().join('')} && ${toDate?.toLocaleDateString('en-GB').split('/').reverse().join('')} && %20 && %20`).then((response: AxiosResponse) => {
            const originalArray = response.data
            const mappedValues = Object.values(originalArray.reduce((a: any, { serviceCategoryId, unitBasedSubtotalCharge, totalCharge, totalCogs, totalNet }: any) => {
                a[serviceCategoryId] = a[serviceCategoryId] || { serviceCategoryId, unitBasedSubtotalCharge: 0, totalCharge: 0, totalCogs: 0, totalNet: 0 };
                a[serviceCategoryId].unitBasedSubtotalCharge += unitBasedSubtotalCharge;
                a[serviceCategoryId].totalCharge += totalCharge;
                a[serviceCategoryId].totalCogs += totalCogs;
                a[serviceCategoryId].totalNet += totalNet;
                return a;
            }, {}));

            const reports = mappedValues as Report[]

            reports.forEach((report: Report) => {
                const object = exivitySerCategories.find((s1) => s1.serviceCategoryId == report.serviceCategoryId)
                if (object != undefined) {
                    report.serviceTypeName = object.name;
                }
            })
            setReport(reports)
            setProfits(reports.map((rep: Report) => rep.totalCharge))
            setServiceNames(reports.map((rep: Report) => rep.serviceTypeName))

            //Do the same for dates to get time stamps
            const dates = originalArray.reduce((a: any, { day }: any) => {
                a[day] = a[day] || { day: [] }
                return { ...a, ...{ [day]: { day } } }
            }, {})
            const dateResult = Object.values(dates)

            setTimeLabels(dateResult.map((date: any) => {
                const year = date["day"].substring(0, 4)
                const month = date["day"].substring(4, 6)
                const day = date["day"].substring(6, 8)
                const newDate = new Date(year, month - 1, day);
                return newDate.toLocaleDateString('en-GB');
            }))


            //Create new array with service_id and total_charge 
            const totalCharges = Object.values(originalArray.reduce((a: any, { serviceCategoryId, totalCharge }: any) => {
                a[serviceCategoryId] = a[serviceCategoryId] || { serviceCategoryId, totalCharge: [] }
                return { ...a, ...{ [serviceCategoryId]: { serviceCategoryId, totalCharge: a[serviceCategoryId].totalCharge.concat(totalCharge) } } }
            }, {}))

            totalCharges.forEach((res: any, index: number) => {
                const object = exivitySerCategories.find((s1) => s1.serviceCategoryId == res.serviceCategoryId)
                if (object != undefined) {
                    res.label = object.name
                }
                res.data = res.totalCharge
                res.borderColor = backgroundColors[index]
                res.backgroundColor = backgroundColors[index]
                delete res.serviceItemName
                delete res.totalCharge
            })

            if (isLineData(totalCharges)) {
                setLineData(totalCharges)
            }
        }).then(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        let isMounted = true;
        let exivitySerCategories: ExivityServiceCategory[] = [];
        get('/Exivity/Account').then((response: AxiosResponse) => {
            if (isMounted) {
                setExivityAccounts(response.data);
            }
        }).then(() => {
            get('/Exivity/ServiceCategory').then((response: AxiosResponse) => {
                if (isMounted) {
                    setExivityServiceCategories(response.data)
                    exivitySerCategories = response.data
                }
            }).then(() => {
                if (isMounted) {
                    getReportsWithNoArguments(exivitySerCategories)
                }
            }).then(() => {
                if (isMounted) {
                    setLoading(false);
                }
            })
        })

        return () => {
            isMounted = false;
        }
    }, [])



    return (
        <>
            <PageHeader
                title="Reports" subtitle="Select the service category, tenant or both to generate a report for"
                icon={<AssessmentIcon />} />
            <ReportHeader getReportsWithNoArguments={getReportsWithNoArguments} setNoArguments={setNoArguments} selectedExivityServiceCategory={selectedExivityServiceCategory} setSelectedExivityServiceCategory={setSelectedExivityServiceCategory} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} exivityServiceCategories={exivityServiceCategories} exivityAccounts={exivityAccounts} setReport={setReport} setProfits={setProfits} setServiceNames={setServiceNames} toDate={toDate} setToDate={setToDate} fromDate={fromDate} setFromDate={setFromDate} setLineData={setLineData} setTimeLabels={setTimeLabels} setLoading={setLoading} />
            {!loading ?
                (width > 960 ? (<>

                    <Grid container spacing={2} >
                        <Grid item xs={3}>
                            <Card sx={{ height: "40vh" }}><ReportPie serviceNames={serviceNames} profits={profits} /></Card>
                        </Grid>
                        <Grid item xs={9}>
                            <Card sx={{ height: "40vh" }}><ReportLine timeLabels={timeLabels} lineData={lineData} /></Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card sx={{ height: "100%" }} ><DashboardSummary report={report} /></Card>
                        </Grid>
                        <Grid item xs={9}>
                            <Card sx={{ width: "100%", height: "100%" }}>
                                {!noArguments ? <ServiceItemDetailed report={report} /> : <ServiceTypeDetailed report={report} />}</Card>
                        </Grid>
                    </Grid>
                </>)
                    :
                    (<>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Card sx={{ height: "50vh" }}><ReportPie serviceNames={serviceNames} profits={profits} /></Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card sx={{ height: "50vh" }}><ReportLine timeLabels={timeLabels} lineData={lineData} /></Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card><DashboardSummary report={report} /></Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card><ServiceItemDetailed report={report} /></Card>
                            </Grid>
                        </Grid> </>)
                ) : (<Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "15vw",
                    }}
                >
                    <PacmanLoader size={40}></PacmanLoader>
                </Box>
                )}
        </>
    )
}

export default Reports