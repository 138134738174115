import { AxiosResponse } from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "../ajax";
import { Tenant } from "../models/Tenant";

interface TenantContextProps {
    selectedTenant: Tenant;
    setSelectedTenant: React.Dispatch<React.SetStateAction<Tenant>>;
}

const TenantContext = createContext<TenantContextProps>({} as TenantContextProps);

export const useTenant = () => {
    return useContext(TenantContext);
};

export function TenantProvider({ children }: { children: React.ReactNode }) {
    const [tenant, setTenant] = useState<Tenant>({
        id: "",
        name: "",
        specialServiceItems: []
    });
    const location: any = useLocation();



    useEffect(() => {
        let isMounted = true;

        const getTenant = () => {
            get("/Tenant").then((response: AxiosResponse) => {
                if (isMounted) {
                    const index = response.data.findIndex((ten: Tenant) => ten.id == location.state.tenant.id);
                    setTenant(response.data[index])
                }
            });
        };
        if (location.state != null) {
            if (tenant.id == "") {
                getTenant();
            }
        }
        return () => { isMounted = false }
    }, [])

    return (
        <TenantContext.Provider value={{ selectedTenant: tenant, setSelectedTenant: setTenant }}>
            {children}
        </TenantContext.Provider>
    );
}
