import { makeStyles } from "@material-ui/styles";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Toolbar } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const useStyles = makeStyles({
  paper: {
    width: "220px",
  },
});

interface Props {
  showSidebar: boolean;
  setShowSidebar: (bool: boolean) => void;
}

const Sidebar = ({ showSidebar, setShowSidebar }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tags } = useAuth();

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      open={showSidebar}
      variant="persistent"
      onClose={() => setShowSidebar(false)}
    >
      <Toolbar />
      <Divider />
      <Divider />
      <List sx={{ width: "100%" }} component="nav">
        <ListItem button key="Dashboard" onClick={() => navigate("/")}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <Divider />
        <ListItem
          button
          key="Services"
          onClick={() => navigate("/service/overview")}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Services" />
        </ListItem>
        <Divider />
        <ListItem
          button
          key="Tenants"
          onClick={() => navigate("/tenant/overview")}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Tenants" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => navigate("/offer/overview")}>
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Offers" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => navigate("/reports")}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
        <Divider />
        {tags.findIndex((tag) => tag.scope == "user:edit") != -1 && (
          <ListItem button key="Admin" onClick={() => navigate("/admin/roles")}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
