import { useMsal } from "@azure/msal-react";
import { makeStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AlertColor,
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../ajax";
import LoadingModal from "../components/Dialogs/LoadingModal";
import ProceedDialog from "../components/Dialogs/ProceedDialog";
import SuccessAlert from "../components/Dialogs/SuccessAlert";
import PageHeader from "../components/PageComponents/PageHeader";
import { useAuth } from "../contexts/AuthContext";
import { errorConverter } from "../hooks/errorConverter";
import { useForm } from "../hooks/useFrom";
import { ServiceItem } from "../models/ServiceItem";
import { ServiceType } from "../models/ServiceType";

interface Props {
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}

const useStyle = makeStyles(() => ({
  root: {
    "& .MuiFormControl-root": {
      margin: "5px",
      marginTop: "10px",
      width: "45%",
    },
    "& .MuiAutocomplete-root": {
      display: "inline",
      flexWrap: "wrap",
    },
  },
}));

/**
 * Container for crerating a new serviceItem
 * @returns NewServiceItemContainer
 */
const NewServiceItemContainer = ({ serviceTypes, setServiceTypes }: Props) => {
  const classes = useStyle();
  const url = window.location.pathname;
  const urlArr = url.split("/");
  const id = urlArr[3];
  const category = serviceTypes.find(
    (serviceType) => serviceType.serviceTypeId == id
  );

  const intervalOptions = [
    "NEVER",
    "INDUVIDUALLY",
    "SECOND",
    "MINUTE",
    "HOUR",
    "DAY",
    "WEEK",
    "MONTH",
    "YEAR",
  ];

  const initialState = {
    reason: "",
    name: "",
    description: "",
    serviceTypeId: category?.serviceTypeId,
    price: 0,
    cogs: 0,
    priceUnit: "",
    currency: "",
    billingInterval: 7,
  };

  // getting the event handlers from our custom hook
  const { onChange, onSubmit, values } = useForm(
    addServiceItemCallback,
    initialState
  );

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [errorAlertText, setErrorAlertText] = useState("");
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const { accounts } = useMsal();
  const email = accounts[0].username;

  const { tags } = useAuth();
  const navigate = useNavigate();

  //blocks url if the tags is changed and the user dont have the right permissions
  useEffect(() => {
    if (
      tags.findIndex((tag) => tag.scope == "serviceItem:post") == -1 &&
      tags[0].scope != "temp"
    ) {
      navigate(-1);
    }
  }, []);

  const onProceedClick = () => {
    const { price, cogs, billingInterval, name } = values;
    values.price = Number(price);
    values.cogs = Number(cogs);
    values.billingInterval = Number(billingInterval);
    values.name = name.trim();

    //to remove reason because it cant be in the post
    const postObj: any = values;
    delete postObj.reason;

    values.userEmail = email;

    setOpenLoadingModal(true);
    post("/ServiceType/ServiceItem", postObj)
      .then((response) => {
        const serviceItemObj: ServiceItem = response.data;
        const serviceTypesList: ServiceType[] = serviceTypes;

        const serviceTypeObj: ServiceType | undefined = serviceTypesList.find(
          (serviceType: { serviceTypeId: string }) =>
            serviceType.serviceTypeId == id
        );
        if (serviceTypeObj != undefined) {
          serviceTypeObj.serviceItems.push(serviceItemObj);
        }

        setServiceTypes(serviceTypesList);
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("success");
      })
      .catch((error) => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("error");
        setErrorAlertText(errorConverter(error.response.data)); //"Service category does not appear on Exivity"
      });
  };

  async function addServiceItemCallback() {
    if (
      values.name == "" ||
      values.description == "" ||
      values.price == 0 ||
      values.cogs == 0 ||
      values.currency == "" ||
      values.priceUnit == ""
    ) {
      setSuccess(true);
      setSeverity("error");
      setErrorAlertText(
        "You have to fill out all the fields before creating the new service!"
      );
    } else {
      setOpen(true);
      setSuccess(false);
    }
  }

  return (
    <>
      <PageHeader
        title={"New Service Item"}
        subtitle={category?.friendlyName}
        icon={<SettingsIcon />}
      />
      <Paper elevation={4}>
        <form name="newServiceItemForm" onSubmit={onSubmit}>
          <FormControl focused fullWidth={true} className={classes.root}>
            <Box>
              <TextField
                name="ServiceTypeId"
                autoComplete="off"
                variant="outlined"
                label="Category"
                disabled={true}
                value={category?.friendlyName}
              />
              <TextField
                autoComplete="off"
                name="name"
                onChange={onChange}
                variant="outlined"
                label="Service Name"
              />
              <TextField
                name="description"
                autoComplete="off"
                onChange={onChange}
                multiline
                variant="outlined"
                label="Description"
              />
              <TextField
                defaultValue={intervalOptions[7]}
                name="billingInterval"
                select
                label="Billing Interval per:"
                onChange={onChange}
              >
                {intervalOptions.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                autoComplete="off"
                type="number"
                variant="outlined"
                label="Price for consumer"
                name="price"
                onChange={onChange}
              />

              <TextField
                name="priceUnit"
                variant="outlined"
                autoComplete="off"
                label="Price Unit"
                onChange={onChange}
              />
              <TextField
                name="cogs"
                onChange={onChange}
                type="number"
                variant="outlined"
                label="Cogs"
              />
              <TextField
                autoComplete="off"
                name="currency"
                variant="outlined"
                label="Currency"
                onChange={onChange}
              />
              <SuccessAlert
                text={
                  severity == "success"
                    ? "You successfully created the service"
                    : errorAlertText
                }
                setOpen={setSuccess}
                open={success}
                severity={severity}
              />

              <Button
                sx={{ marginTop: "10px" }}
                fullWidth={true}
                color="success"
                variant="contained"
                endIcon={<AddIcon />}
                type="submit"
              >
                {" "}
                Add service{" "}
              </Button>
              <LoadingModal text="Updating exivity" open={openLoadingModal} />
              <ProceedDialog
                onProceedAction={onProceedClick}
                text="Are you sure you want to create the service?"
                setOpen={setOpen}
                open={open}
              />
            </Box>
          </FormControl>
        </form>

        <Divider variant="middle" />
      </Paper>
    </>
  );
};

export default NewServiceItemContainer;
