import { Typography } from "@mui/material";
import React from "react";

interface Props {
  text: string;
  inputValue: string;
}
//Takes origignal string and the search value and highlites the searched word
const HighlitedText = ({ text, inputValue }: Props) => {
  const name = text.toLocaleLowerCase();
  const input = inputValue.toLocaleLowerCase();
  if (name.includes(input)) {
    const searched = name.indexOf(input);
    const sub = text.substring(searched, searched + input.length);
    const strBeforeInput = text.substring(0, searched);
    const strAfterInput = text.substring(searched + input.length, name.length);
    return (
      <Typography>
        {strBeforeInput}
        <span style={{ color: "red" }}>{sub}</span>
        {strAfterInput}
      </Typography>
    );
  } else {
    return <Typography>{text} </Typography>;
  }
};

export default HighlitedText;
