import { makeStyles } from "@material-ui/styles";
import { CardContent, Typography } from "@mui/material";
import React from "react";
import { Report } from "../../models/Report";

const useStyles = makeStyles({
  typography: {
    paddingBottom: "15px",
  },
  summary: {
    marginLeft: "0",
  },
});

interface Props {
  report: Report[];
}

/**
 * Component for summary in Dashboard 
 * @param {report[]} report Information from exivity. Used for information in summary
 * @returns DashboardSummary
 */
const DashboardSummary = ({ report }: Props) => {
    const classes = useStyles();
    const NOK = Intl.NumberFormat('no-NO')


    const getTotalCharge = () => {
        let totalCharge = 0;
        report.forEach(rep => (
            totalCharge += +rep.totalCharge
        ));
        return NOK.format(totalCharge)
    }

    const getTotalCogs = () => {
        let totalCogs = 0;
        report.forEach(rep => (
            totalCogs += +rep.totalCogs
        ));
        return NOK.format(totalCogs)
    }

    const getTotalNet = () => {
        let totalNet = 0;
        report.forEach(rep => (
            totalNet += +rep.totalNet
        ));
        return NOK.format(totalNet)
    }

    return (
        <CardContent>
            <Typography gutterBottom variant="h4" component="div">Summary</Typography>
            <Typography variant="subtitle2">TOTAL CHARGE</Typography>
            <Typography className={classes.typography} gutterBottom variant="h5">NOK {getTotalCharge()}</Typography>
            <Typography variant="subtitle2">TOTAL COGS</Typography>
            <Typography className={classes.typography} gutterBottom variant="h5">NOK {getTotalCogs()}</Typography>
            <Typography variant="subtitle2">TOTAL NET</Typography>
            <Typography className={classes.typography} gutterBottom variant="h5">NOK {getTotalNet()}</Typography>
        </CardContent>
    )
}

export default DashboardSummary;
