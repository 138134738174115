import { AlertColor, Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from 'react';
import { put } from '../../ajax';
import { errorConverter } from '../../hooks/errorConverter';
import { BillingInterval } from '../../models/BillingInterval';
import { SpecialRate } from '../../models/SpecialRate';
import { SpecialServiceItem } from '../../models/SpecialServiceItem';
import { Tenant } from '../../models/Tenant';
import LoadingModal from "../Dialogs/LoadingModal";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    selectedServiceItem: SpecialServiceItem;
    setSelectedServiceItem: React.Dispatch<React.SetStateAction<SpecialServiceItem>>
    tenant: Tenant;
    setTenant: React.Dispatch<React.SetStateAction<Tenant>>;
    open: boolean;
    setOpen: (bool: boolean) => void;
    title: string;
    setSuccessText: React.Dispatch<React.SetStateAction<string>>;
    setErrorAlertText: React.Dispatch<React.SetStateAction<string>>;
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setSeverity: React.Dispatch<React.SetStateAction<AlertColor | undefined>>;
}

/**
 * Dialog to edit or add a special price for a tenant
 * */
const EditSpecialPriceDialog = ({ selectedServiceItem, setSelectedServiceItem, tenant, setTenant, open, setOpen, title, setSuccessText, setErrorAlertText, setSuccess, setSeverity }: Props) => {
    const [discount, setDiscount] = useState<number>(0);
    const [specialPrice, setSpecialPrice] = useState<number>(0);
    const [viewDiscount, setViewDiscount] = useState<string>("");
    const [viewSpecialPrice, setViewSpecialPrice] = useState<string>("");
    const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);


    useEffect(() => {
        setDiscount(100 - (selectedServiceItem.rate.specialPrice / selectedServiceItem.rate.price * 100))
        setSpecialPrice(selectedServiceItem.rate.specialPrice)
        setViewDiscount((100 - (selectedServiceItem.rate.specialPrice / selectedServiceItem.rate.price * 100)).toFixed(2))
        setViewSpecialPrice((selectedServiceItem.rate.specialPrice).toFixed(3))
    }, [selectedServiceItem]);


    const onProceedAction = () => {
        setOpenLoadingModal(true);
        const item = {
            specialRateId: selectedServiceItem.rate.specialRateId,
            specialPrice: specialPrice,
            specialCogs: selectedServiceItem.rate.cogs,
            exivityId: selectedServiceItem.rate.exivityId
        }

        put("/SpecialRate", item)
            .then((response) => {

                const newTenant = tenant;
                const serviceItemIndex = newTenant.specialServiceItems.findIndex(
                    (obj) => (obj.serviceItemId == selectedServiceItem.serviceItemId)
                )

                const newSelectedServiceItem = newTenant.specialServiceItems[serviceItemIndex];
                newSelectedServiceItem.rate.specialPrice = response.data.specialPrice;
                newTenant.specialServiceItems[serviceItemIndex] = newSelectedServiceItem;

                const newRate: SpecialRate = {
                    billingInterval: selectedServiceItem.rate.billingInterval,
                    cogs: selectedServiceItem.rate.cogs,
                    currency: selectedServiceItem.rate.currency,
                    price: selectedServiceItem.rate.price,
                    priceUnit: selectedServiceItem.rate.priceUnit,
                    rateHistory: selectedServiceItem.rate.rateHistory,
                    rateId: selectedServiceItem.rate.rateId,
                    specialPrice: specialPrice,
                    specialRateId: selectedServiceItem.rate.specialRateId,
                    specialCogs: 10,
                    exivityId: selectedServiceItem.rate.exivityId
                }
                setSelectedServiceItem({ ...selectedServiceItem, rate: newRate });
                setTenant(newTenant)
            }).then(() => {
                setOpenLoadingModal(false);
                setSuccess(true);
                setSeverity("success");
                setSuccessText("Successfully edited special price");
            }).catch((error) => {
                setOpenLoadingModal(false);
                setSuccess(true);
                setSeverity("error");
                setErrorAlertText(errorConverter(error.response.data));
            });
    }

    const onProceedClick = () => {
        onProceedAction();
        setOpen(false);
    };

    const onRejectClick = () => {
        setOpen(false);
    };

    const handleDiscountChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setViewDiscount(e.target.value);
        setDiscount(Number(e.target.value));
        const disc = (100 - Number(e.target.value)) / 100
        const oldPrice = selectedServiceItem.rate.price;
        const newPrice = disc * oldPrice;
        setSpecialPrice(Number((newPrice).toFixed(3)))
        setViewSpecialPrice((newPrice).toFixed(3))
    }

    const handleNewPriceChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setViewSpecialPrice(e.target.value);
        setSpecialPrice(Number(e.target.value))
        setDiscount(Number(((1 - Number(e.target.value) / selectedServiceItem.rate.price) * 100).toFixed(2)))
        setViewDiscount(((1 - Number(e.target.value) / selectedServiceItem.rate.price) * 100).toFixed(2))
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                <DialogTitle>{title}</DialogTitle>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel>Select a service</InputLabel>
                    <Select
                        value={selectedServiceItem.name}
                        input={<OutlinedInput label="Name" />}
                        disabled
                    >
                        <MenuItem value={selectedServiceItem.name}>{selectedServiceItem.name}</MenuItem>
                    </Select>
                </FormControl>
                <Divider />
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>{"Description: "}</TableCell>
                                <TableCell colSpan={1}>{selectedServiceItem.description}</TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >{"Original Price: "}</TableCell>
                                <TableCell>
                                    {
                                        selectedServiceItem.rate.price +
                                        " " +
                                        selectedServiceItem.rate.priceUnit
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{"Billing interval: "}</TableCell>
                                <TableCell>
                                    {
                                        BillingInterval[
                                        selectedServiceItem.rate.billingInterval
                                        ]
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{"COGS: "}</TableCell>
                                <TableCell>
                                    {
                                        selectedServiceItem.rate.cogs +
                                        " " +
                                        selectedServiceItem.rate.currency
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{"Discount: "}</TableCell>
                                <TableCell>
                                    <TextField onChange={handleDiscountChange} value={viewDiscount} error={discount < 0 || discount > 100} disabled={selectedServiceItem === undefined} inputProps={{
                                        style: { textAlign: 'right' }, maxLength: 5
                                    }} /> %
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{"New price: "}</TableCell>
                                <TableCell>
                                    <TextField onChange={handleNewPriceChange} value={viewSpecialPrice} disabled={selectedServiceItem === undefined} inputProps={{
                                        style: { textAlign: "right" },
                                    }} />
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <Button
                        onClick={onProceedClick}
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="success"
                        disabled={selectedServiceItem === undefined || specialPrice < 0 || isNaN(specialPrice)}
                    >
                        Edit
                    </Button>
                    <Button
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="error"
                        onClick={onRejectClick}
                    >
                        Exit
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingModal text="Updating with new price" open={openLoadingModal} />
        </>)
}

export default EditSpecialPriceDialog;