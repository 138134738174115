import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { get } from "../ajax";
import PageHeader from "../components/PageComponents/PageHeader";
import UserInformation from "../components/UserComponents/UserInformation";
import UserSearch from "../components/UserComponents/UserSearch";
import { useAuth } from "../contexts/AuthContext";
import { Tag } from "../models/Tag";
import { User } from "../models/User";

/**
 * Container for all user components
 * @returns Roles
 */

const Roles = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [mounted, setMounted] = useState<boolean>(false);
  const { tags } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (tags.findIndex((tag) => tag.scope == "user:edit") == -1) {
      navigate(-1);
    }
  }, [tags]);

  useEffect(() => {
    let isMounted = true;
    get("/User").then((response: AxiosResponse) => {
      if (isMounted) {
        setUsers(response.data);
        setMounted(true);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    get("/Tag").then((response: AxiosResponse) => {
      if (isMounted) {
        setTagList(response.data);
        setMounted(true);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <PageHeader
        title="Roles"
        subtitle={"Search for a user to modify permission"}
        icon={<AdminPanelSettingsIcon />}
      ></PageHeader>
      {mounted ? (
        <Paper elevation={4}>
          <UserSearch users={users} setSelectedUser={setSelectedUser} />
          {selectedUser !== undefined ? (
            <>
              <Typography variant="h4">{selectedUser.email}</Typography>
              <Divider />
              <UserInformation
                user={selectedUser}
                setUser={setSelectedUser}
                tags={tagList}
                users={users}
                setUsers={setUsers}
              />
            </>
          ) : (
            <></>
          )}
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "15vw",
          }}
        >
          <PacmanLoader size={40}></PacmanLoader>
        </Box>
      )}
    </div>
  );
};

export default Roles;
