import DashboardIcon from "@mui/icons-material/Dashboard";
import { Box, Card, Grid } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { PacmanLoader } from "react-spinners";
import { get } from "../ajax";
import DashboardBar from "../components/DashboardComponents/DashboardBar";
import DashboardDetailed from "../components/DashboardComponents/DashboardDetailed";
import DashboardPie from "../components/DashboardComponents/DashboardPie";
import DashboardSummary from "../components/DashboardComponents/DashboardSummary";
import PageHeader from "../components/PageComponents/PageHeader";
import useWindowSize from "../hooks/useWindowSize";
import { Report } from "../models/Report";

/**
 * Container for all dashboard components
 * @returns DashboardContainer
 */

const DashboardContainer = () => {
  const { width } = useWindowSize();
  const [report, setReport] = React.useState<Report[]>([]);
  const [profits, setProfits] = React.useState<number[]>([]);
  const [mounted, setMounted] = React.useState<boolean>(false);
  const [serviceTypeNames, setServiceTypeNames] = React.useState<string[]>([]);

  useEffect(() => {
    let isMounted = true;
    const fetchReports = async () => {
      const end = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("");

      const start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("");

      await get(`/Report/Dashboard/${start} && ${end}`).then(
        (response: AxiosResponse) => {
          if (isMounted) {
            const originalArray = response.data;

            const mappedValues = Object.values(
              originalArray.reduce(
                (
                  a: any,
                  {
                    serviceTypeName,
                    unitBasedSubtotalCharge,
                    totalCharge,
                    totalCogs,
                    totalNet,
                  }: any
                ) => {
                  a[serviceTypeName] = a[serviceTypeName] || {
                    serviceTypeName,
                    unitBasedSubtotalCharge: 0,
                    totalCharge: 0,
                    totalCogs: 0,
                    totalNet: 0,
                  };
                  a[serviceTypeName].unitBasedSubtotalCharge +=
                    unitBasedSubtotalCharge;
                  a[serviceTypeName].totalCharge += totalCharge;
                  a[serviceTypeName].totalCogs += totalCogs;
                  a[serviceTypeName].totalNet += totalNet;
                  return a;
                },
                {}
              )
            );

            setReport(mappedValues as Report[]);

            setProfits(
              mappedValues.map((rep: any) => rep.unitBasedSubtotalCharge)
            );
            setServiceTypeNames(
              mappedValues.map((rep: any) => rep.serviceTypeName)
            );
            setMounted(true);
          }
        }
      );
    };

    fetchReports();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <PageHeader
        title="Dashboard"
        subtitle="Statistics for the last 7 days"
        icon={<DashboardIcon />}
      />
      {mounted ? (
        width > 960 ? (
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3}>
              <Card sx={{ height: "40vh" }}>
                <DashboardPie
                  serviceTypeNames={serviceTypeNames}
                  profits={profits}
                />
              </Card>
            </Grid>
            <Grid item xs={9} sm={9}>
              <Card sx={{ height: "40vh" }}>
                <DashboardBar
                  serviceTypeNames={serviceTypeNames}
                  profits={profits}
                />
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ height: "100%" }}>
                <DashboardSummary report={report} />
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Card sx={{ width: "100%", height: "100%" }}>
                <DashboardDetailed report={report} />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{}}>
            <Grid item xs={12}>
              <Card sx={{ height: "50vh" }}>
                <DashboardPie
                  serviceTypeNames={serviceTypeNames}
                  profits={profits}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ height: "50vh" }}>
                <DashboardBar
                  serviceTypeNames={serviceTypeNames}
                  profits={profits}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <DashboardSummary report={report} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <DashboardDetailed report={report} />
              </Card>
            </Grid>
          </Grid>
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "15vw",
          }}
        >
          <PacmanLoader size={40}></PacmanLoader>
        </Box>
      )}
    </>
  );
};

export default DashboardContainer;
