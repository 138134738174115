import EditIcon from "@mui/icons-material/Edit";
import { IconButton, ListItemButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { dateConverter } from "../../hooks/DateConverter";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import HighlitedText from "../SearchComponents/HighlitedText";

interface Props {
  serviceType: ServiceType;
  serviceItem: ServiceItem;
  inputValue: string;
}
/**
 * Component contianing clickable button for each serviceItem and editing button
 * @param {serviceType} serviceType Related serviceType used to display information
 * @param {ServiceItem} serviceItem Realed serviceItem used to display information
 * @param {string} inputValue Used for highliting searched serviceItems
 * @returns ServiceItemButton
 */
const ServiceItemButton = ({ serviceType, serviceItem, inputValue }: Props) => {
  const navigate = useNavigate();
  const { tags } = useAuth();

  return (
    <>
      <ListItemButton
        sx={{ display: "flex", justifyContent: "space-between" }}
        onClick={() =>
          navigate(`${serviceType.serviceTypeId}/${serviceItem.name}`)
        }
      >
         <HighlitedText text={serviceItem.name} inputValue={inputValue} />
        <Typography>
          {" Last updated: (" +
            dateConverter(serviceItem.rate.rateHistory[0].dateChanged) +
            ")"}{" "}
        </Typography>
      </ListItemButton>
      <Tooltip
        title={
          tags.findIndex((tag) => tag.scope == "serviceItem:put") == -1
            ? "No permission"
            : "Edit Service"
        }
      >
        <span>
          <IconButton
            onClick={() =>
              navigate(
                `${serviceType.serviceTypeId}/${serviceItem.serviceItemId}/editService`
              )
            }
            sx={{
              borderRadius: "0",
              borderLeft: "none",
            }}
            disabled={
              tags.findIndex((tag) => tag.scope == "serviceItem:put") == -1
            }
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default ServiceItemButton;
