import { makeStyles } from "@material-ui/styles";
import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { ServiceType } from "../../models/ServiceType";

const useStyles = makeStyles({
  TextField: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#202020",
  },
  multilineColor: {
    color: "#202020",
  },
});

interface Props {
  serviceTypes: ServiceType[];
  setChosenServiceTypes: (serviceTypes: ServiceType[]) => void;
  chosenServiceTypes: ServiceType[];
}

/**
 * Component for choosing serviceTypes for offers
 * @param {ServiceType[]} serviceTypes list of all serviceTypes
 * @param {function(serviceType[])} setChosenServiceTypes function to add new serviceType
 * @param {ServiceType[]} chosenServiceTypes list of already included servicetypes
 * @returns Search bar with list including all servicetypes
 */

const OfferServiceSearch = ({
  serviceTypes,
  chosenServiceTypes,
  setChosenServiceTypes,
}: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>("");

  const handleSearchClick = (event: any, value: ServiceType | null) => {
    if (
      value != null &&
      chosenServiceTypes.filter((e) => e.serviceTypeId === value.serviceTypeId)
        .length == 0
    ) {
      const temp: ServiceType[] = [...chosenServiceTypes];
      const tempvalue = JSON.parse(JSON.stringify(value));
      temp.unshift(tempvalue);
      setChosenServiceTypes(temp);
    }
  };

  const handleInputChange = (event: any, value: string, reason: any) => {
    if (reason === "reset") {
      setInputValue("");
      return;
    } else {
      setInputValue(value);
    }
  };

  return (
    <Autocomplete
      sx={{ marginBottom: "10px" }}
      fullWidth={true}
      classes={{ root: classes.TextField }}
      id="offer-search"
      options={serviceTypes}
      inputValue={inputValue}
      onChange={handleSearchClick}
      onInputChange={handleInputChange}
      autoHighlight
      noOptionsText="No service type with this name"
      isOptionEqualToValue={(option, value) =>
        option.serviceTypeId === value.serviceTypeId
      }
      getOptionLabel={(option: ServiceType) => option.friendlyName}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.friendlyName}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          variant="filled"
          {...params}
          label="Search for service types and click to add"
          InputLabelProps={{
            style: {
              color: "black",
            },
          }}
          inputProps={{
            className: classes.multilineColor,
            ...params.inputProps,
            autoComplete: "off",
          }}
        />
      )}
    />
  );
};

export default OfferServiceSearch;
