import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ConstructionIcon from "@mui/icons-material/Construction";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  AlertColor,
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { remove } from "../../ajax";
import { useAuth } from "../../contexts/AuthContext";
import { errorConverter } from "../../hooks/errorConverter";
import { SpecialServiceItem } from "../../models/SpecialServiceItem";
import { Tenant } from "../../models/Tenant";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";
import EditSpecialPriceDialog from "../TenantComponents/EditSpecialPriceDialog";
import TenantServiceItemInfo from "../TenantComponents/TenantServiceItemInfo";

interface Props {
  serviceItem: SpecialServiceItem;
  tenant: Tenant;
  setTenant: React.Dispatch<React.SetStateAction<Tenant>>;
  setSuccessText: React.Dispatch<React.SetStateAction<string>>;
  setErrorAlertText: React.Dispatch<React.SetStateAction<string>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setSeverity: React.Dispatch<React.SetStateAction<AlertColor | undefined>>;
}

const TenantServiceItem = ({ serviceItem, tenant, setTenant, setSuccessText, setErrorAlertText, setSuccess, setSeverity }: Props) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedServiceItem, setSelectedServiceItem] = useState<SpecialServiceItem>(serviceItem);
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);
  const { tags } = useAuth();


  useEffect(() => {
    let isMounted = true;
    const getSelectedServiceItem = () => {
      if (isMounted) {
        setSelectedServiceItem(serviceItem);
      }
    }
    getSelectedServiceItem();
    return () => { isMounted = false };
  }, []);


  const handleDelete = () => {
    setOpenLoadingModal(true);
    remove(`SpecialRate/${selectedServiceItem.rate.specialRateId} && ${selectedServiceItem.rate.exivityId}`).then(() => {
      const newTenant = tenant;
      const specialServiceItemsList = newTenant.specialServiceItems.filter((serviceItem: SpecialServiceItem) => serviceItem.serviceItemId !== selectedServiceItem.serviceItemId);
      newTenant.specialServiceItems = specialServiceItemsList;
      setTenant(newTenant)
    }).then(() => {
      setOpenLoadingModal(false);
      setSuccess(true);
      setSeverity("success");
      setSuccessText("Successfully deleted special price");
    }).catch((error) => {
      setOpenLoadingModal(false);
      setSuccess(true);
      setSeverity("error");
      setErrorAlertText(errorConverter(error.response));
    })
  }



  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>

      <Box key={selectedServiceItem.serviceItemId} sx={{ width: "100%" }}>
        <ListItemButton
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-evenly",
          }}
          alignItems="center"
          onClick={() => setOpenInfo(!openInfo)}
        >
          <ConstructionIcon />
          <ListItemText primary={selectedServiceItem.name}></ListItemText>
          {openInfo ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openInfo} timeout="auto" unmountOnExit>
          <TenantServiceItemInfo
            serviceItem={selectedServiceItem}
          ></TenantServiceItemInfo>
        </Collapse>
        <Divider />
      </Box>
      <Tooltip
        title={
          tags.findIndex((tag) => tag.scope == "specialRate:put") == -1
            ? "No permission"
            : "Edit"
        }
      >
        <span>
          <IconButton
            disabled={
              tags.findIndex((tag) => tag.scope == "specialRate:put") == -1
            }
            onClick={() => setOpenEdit(!openEdit)}
            sx={{
              borderRadius: "0",
              borderLeft: "solid 1px rgba(0, 0, 0, 0.1)",
            }}
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={
          tags.findIndex((tag) => tag.scope == "specialRate:delete") == -1
            ? "No permission"
            : "Delete"
        }
      >
        <span>
          <IconButton
            disabled={
              tags.findIndex((tag) => tag.scope == "specialRate:delete") == -1
            }
            onClick={() => setOpenDelete(!openDelete)}
            sx={{
              borderRadius: "0",
              borderLeft: "solid 1px rgba(0, 0, 0, 0.1)",
            }}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <ProceedDialog
        text={
          "Do you want to remove the discount for \n '" +
          selectedServiceItem.name +
          "'?"
        }
        open={openDelete}
        setOpen={setOpenDelete}
        onProceedAction={handleDelete}
      />
      <EditSpecialPriceDialog
        selectedServiceItem={selectedServiceItem}
        setSelectedServiceItem={setSelectedServiceItem}
        tenant={tenant}
        setTenant={setTenant}
        open={openEdit}
        setOpen={setOpenEdit}
        title={"Edit discount"}
        setSuccessText={setSuccessText}
        setErrorAlertText={setErrorAlertText}
        setSuccess={setSuccess}
        setSeverity={setSeverity}
      />
      <LoadingModal text="Deleting special price" open={openLoadingModal} />
    </Box>
  );
};

export default TenantServiceItem;
