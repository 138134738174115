import { Typography } from '@mui/material';
import React from 'react';
import LoginNavbar from '../components/LoginComponents/LoginNavbar';

interface Props {
    isDarkMode: boolean;
    updateDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Container used when users types in url's that do not exist
 * @returns NotFound
 */
const NotFound = ({ isDarkMode, updateDarkMode }: Props) => {
    return (
        <>
            <LoginNavbar isDarkMode={isDarkMode} updateDarkmode={updateDarkMode} />
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "10px",
            }}>
                <Typography variant="h1">404 Not Found</Typography>
                <Typography variant="h5">This is not the page you are looking for</Typography>
            </div>
        </>
    )
}

export default NotFound