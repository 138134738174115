import AddIcon from "@mui/icons-material/Add";
import {
  AlertColor,
  Divider,
  ListItemButton,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { ServiceItem } from "../../models/ServiceItem";
import { SpecialServiceItem } from "../../models/SpecialServiceItem";
import { Tenant } from "../../models/Tenant";
import SuccessAlert from "../Dialogs/SuccessAlert";
import TenantServiceItem from "../ServiceComponents/TenantServiceItem";
import NewSpecialPriceDialog from "./NewSpecialPriceDialog";

interface Props {
  tenant: Tenant;
  setTenant: React.Dispatch<React.SetStateAction<Tenant>>;
  allServiceItems: ServiceItem[];
}
/**
 * Component inlucding tenant serviceitem overview
 * @param {Tenant} tenant Chosen tenant
 * @param {function} setTenantnant Used to choose new tenant
 * @param {ServiceItem[]} allServiceItems List of all serviceItems for chosen tenant
 * @returns TenantPageServiceItems
 */

const TenantPageServiceItems = ({ tenant, setTenant, allServiceItems, }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [filteredServiceItems, setFilteredServiceItems] = useState<ServiceItem[]>(allServiceItems.filter(item1 => !tenant.specialServiceItems.find(item2 => item1.serviceItemId == item2.serviceItemId)));
  const [errorAlertText, setErrorAlertText] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [successText, setSuccessText] = useState<string>("");
  const { tags } = useAuth();


  useEffect(() => {
    setFilteredServiceItems(allServiceItems.filter(item1 => !tenant.specialServiceItems.find(item2 => item1.serviceItemId == item2.serviceItemId)))
  }, [tenant.specialServiceItems.length]);


  return (
    <>
      <Tooltip
        title={
          tags.findIndex((tag) => tag.scope == "specialRate:post") == -1
            ? "No permission"
            : "Add a new special price"
        }
      >
        <span>
          <ListItemButton
            sx={{
              display: "flex",
              textAlign: "center",
              justifyContent: "space-evenly",
            }}
            disabled={
              tags.findIndex((tag) => tag.scope == "specialRate:post") == -1
            }
            onClick={() => setOpen(!open)}
          >
            <AddIcon />{/* <ListItemText primary="Add a new service for this tenant" /> */}
          </ListItemButton >
          </span>
      </Tooltip >
      <Divider />
      {
        tenant.specialServiceItems.length > 0 ? tenant.specialServiceItems.map((serviceItem: SpecialServiceItem) => (
          <TenantServiceItem key={serviceItem.rate.specialRateId} serviceItem={serviceItem} tenant={tenant} setTenant={setTenant} setSuccessText={setSuccessText} setErrorAlertText={setErrorAlertText} setSuccess={setSuccess} setSeverity={setSeverity} />
        )) : <Typography>This tenant does currently not have any services</Typography>
      }
      <SuccessAlert
        text={
          severity == "success"
            ? successText
            : errorAlertText
        }
        setOpen={setSuccess}
        open={success}
        severity={severity}
      />
      <NewSpecialPriceDialog filteredServiceItems={filteredServiceItems} tenant={tenant} setTenant={setTenant} open={open} setOpen={setOpen} title={"Add a new special price for " + tenant.name} setSuccessText={setSuccessText} setErrorAlertText={setErrorAlertText} setSuccess={setSuccess} setSeverity={setSeverity} />
    </>
  )
}

export default TenantPageServiceItems;
