import { useTheme } from "@mui/material/styles";
import {
    ArcElement, CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import React from 'react';
import { Pie } from "react-chartjs-2";
import { backgroundColors } from '../../colors';


interface Props {
    serviceTypeNames: string[];
    profits: number[];
}


ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
);

/**
 * Component for Pie diagram in Dashboard
 * @param {string[]} serviceTypeNames List with serviceType names
 * @param {number[]} profits List with profit for related serviceType
 * @returns DashboardPie
 */
const DashboardPie = ({ serviceTypeNames, profits }: Props) => {
    const theme = useTheme();

    const data = {
        labels: serviceTypeNames,
        datasets: [
            {
                label: "Profit in NOK",
                data: profits,
                backgroundColor: backgroundColors,
                borderWidth: 1,
            },
        ],
    };


    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: "Top Services Types",
                color: `${theme.palette.text.primary}`,
                font: {
                    size: 20,
                },
            },
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };


    return (
        <Pie data={data} options={pieOptions} />
    )
}

export default DashboardPie