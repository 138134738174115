import GroupIcon from '@mui/icons-material/Group';
import { Box, Paper } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { PacmanLoader } from 'react-spinners';
import { get } from '../ajax';
import PageHeader from '../components/PageComponents/PageHeader';
import TenantCard from '../components/TenantComponents/TenantCard';
import TenantSearch from '../components/TenantComponents/TenantSearch';
import { Tenant } from '../models/Tenant';


/**
 * Contrainer for tenant components
 * @returns TenantOverview
 */
const TenantOverview = () => {
    const [inputValue, setInputValue] = useState<string>("");
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        let isMounted = true;
        const getTenants = () => {
            get("/Tenant").then((response: AxiosResponse) => {
                if (isMounted) {
                    setTenants(response.data)
                    setMounted(true);
                }
            });
        }
        getTenants();
        return () => { isMounted = false }
    }, []);

    const hasInput = (str: string) => {
        return str.toLowerCase().includes(inputValue.toLowerCase());
    };

    const checkMetricsDescription = (tenants: Tenant[]) => {
        if (inputValue == "") {
            return tenants;
        } else {
            return tenants.filter((tenant: Tenant) => {
                return hasInput(tenant.name);
            });
        }
    };

    const filterTenants = checkMetricsDescription(tenants);


    return (
        <>
            < PageHeader
                title="Tenant Overview"
                subtitle="Click on a tenant to view more information"
                icon={< GroupIcon />}
            />{
                mounted ?
                    <Box>

                        < Paper elevation={4} >
                            <TenantSearch
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                            />
                            {
                                filterTenants.map((tenant: Tenant) => (
                                    <TenantCard
                                        tenant={tenant}
                                        key={tenant.id}
                                    />
                                ))
                            }
                        </Paper >
                    </Box > : <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            width: "100%",
                            marginTop: "15vw",
                        }}
                    >
                        <PacmanLoader size={40}></PacmanLoader>
                    </Box>}
        </>
    )
}

export default TenantOverview