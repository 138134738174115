import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import { Box, List, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import PageHeader from "../components/PageComponents/PageHeader";
import ServiceSearch from "../components/ServiceComponents/ServiceSearch";
import ServiceTypeCard from "../components/ServiceComponents/ServiceTypeCard";
import useIsMounted from "../hooks/useIsMounted";
import { ServiceType } from "../models/ServiceType";

interface Props {
  serviceTypes: ServiceType[];
}

/**
 * Container for servicetype overview
 * @param serviceTypes ServiceType[]
 * @returns Container with searchbar, pageheader and list of all ServiceTypeCard's
 */
const ServiceOverViewContainer = ({ serviceTypes }: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [mounted, setMounted] = useState(false);

  let isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted) {
      setMounted(true);
    }
    setTimeout(() => {
      if (isMounted) {
        setMounted(false);
      }
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * Checks if user input exist in either servicetype name or serviceItem name
   * @param str input string form user
   * @returns boolean
   */
  const hasInput = (str: string) => {
    return str.toLowerCase().includes(inputValue.toLowerCase());
  };

  /**
   * Checks serviceTypes with hasInput()
   * @param serviceType ServiceType
   * @returns List of serviceTypes
   */
  const checkServiceItemName = (serviceType: ServiceType) => {
    if (inputValue == "") {
      return [];
    } else {
      return serviceType.serviceItems.filter((serviceItem) => {
        return hasInput(serviceItem.name);
      });
    }
  };
  /**
   * Gets list ready to be maped as a list of serviceTypeCard's
   */
  const filterServices = serviceTypes.filter((val) => {
    const list = checkServiceItemName(val);
    return hasInput(val.name) || list.length >= 1;
  });

  return (
    <React.Fragment>
      <PageHeader
        title="Service Overview"
        subtitle="Service types with all services"
        icon={<ViewAgendaIcon />}
      />
      {mounted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "15vw",
          }}
        >
          <PacmanLoader size={40}></PacmanLoader>
        </Box>
      ) : (
        <Box>
          <Paper elevation={4}>
            <ServiceSearch
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            {filterServices.map((serviceType: ServiceType) => (
              <List
                sx={{
                  paddingTop: "0",
                  paddingBottom: "0",
                  border: "solid 1px rgba(0, 0, 0, 0.1)",
                }}
                key={serviceType.serviceTypeId}
              >
                <ServiceTypeCard
                  inputValue={inputValue}
                  serviceItems={checkServiceItemName(serviceType)}
                  serviceType={serviceType}
                />
              </List>
            ))}
          </Paper>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ServiceOverViewContainer;
