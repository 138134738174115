import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CardHeader, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  subtitle?: string;
  icon: JSX.Element;
}

/**
 * Component for displaying information about each page
 * @param {string} title Title for given page
 * @param {string} subtitle Subtitle for the given page
 * @param {Element} icon Icon used in pageheader
 * @returns  PageHeader
 */
const PageHeader = ({ title, subtitle, icon }: Props) => {
  const navigate = useNavigate();

  return (
    <Paper
      component={CardHeader}
      elevation={4}
      sx={{ mariginTop: "0", marginBottom: "10px" }}
      title={<Typography variant="h6"> {title} </Typography>}
      avatar={icon}
      subheader={<Typography variant="caption"> {subtitle} </Typography>}
      action={
        <>
          <IconButton
            aria-label="Navigate back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            aria-label="Navigate forward"
            onClick={() => {
              navigate(+1);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      }
    ></Paper>
  );
};

export default PageHeader;
