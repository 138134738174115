import { TextField } from '@mui/material';
import React from 'react';

interface Props {
    inputValue: string,
    setInputValue: (str: string) => void,
}
/**
 * Search field for Tenants
 * @param {string} inputValue 
 * @param {function} setInputValue 
 * @returns TenantSearch
 */
const TenantSearch = ({ inputValue, setInputValue }: Props) => {

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value)
    };

    return (
        <TextField
            value={inputValue}
            onChange={handleInputChange}
            sx={{ width: "100%", rigth: "0" }}
            variant='filled'
            label="Search for a tenant"
            InputLabelProps={{
                style: {
                    color: "black",
                }
            }}
            inputProps={{
                autoComplete: 'off', // disable autocomplete and autofill
            }}
        />
    )
}

export default TenantSearch;
