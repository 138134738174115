import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect 
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest)
  };

  return (
    <Button
      id="signIn"
      endIcon={<LoginIcon />}
      sx={{
        width: "70%",
        height: "50%",
        marginBottom: "10px",
      }}
      variant="contained"
      color="primary"
      onClick={() => {
        handleLogin();
      }}
    >
      sign in
    </Button>
  );
};
