import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import axios, { AxiosResponse } from "axios";
import React from 'react';
import { backgroundColors } from '../../colors';
import { ExivityAccount } from "../../models/ExivityAccount";
import { ExivityServiceCategory } from "../../models/ExivityServiceCategory";
import { Report } from "../../models/Report";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


type lineDataInterface = {
    label: string,
    data: number[];
    borderColor: string;
    backgroundColor: string;
}


interface Props {
    getReportsWithNoArguments: (exivitySerCategories: ExivityServiceCategory[]) => Promise<void>
    setNoArguments: React.Dispatch<React.SetStateAction<boolean>>;
    selectedExivityServiceCategory: ExivityServiceCategory | undefined;
    setSelectedExivityServiceCategory: React.Dispatch<React.SetStateAction<ExivityServiceCategory | undefined>>;
    selectedAccount: ExivityAccount | undefined;
    setSelectedAccount: React.Dispatch<React.SetStateAction<ExivityAccount | undefined>>;
    exivityServiceCategories: ExivityServiceCategory[];
    exivityAccounts: ExivityAccount[];
    setReport: React.Dispatch<React.SetStateAction<Report[]>>;
    setProfits: React.Dispatch<React.SetStateAction<number[]>>;
    setServiceNames: React.Dispatch<React.SetStateAction<string[]>>;
    toDate: Date | null;
    setToDate: React.Dispatch<React.SetStateAction<Date | null>>;
    fromDate: Date | null;
    setFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setLineData: React.Dispatch<React.SetStateAction<lineDataInterface[]>>;
    setTimeLabels: React.Dispatch<React.SetStateAction<string[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * Component for choosing what reports to get from exivity
 * @returns ReportHeader
 */

const ReportHeader = ({ getReportsWithNoArguments, setNoArguments, selectedExivityServiceCategory, setSelectedExivityServiceCategory, selectedAccount, setSelectedAccount, exivityServiceCategories, exivityAccounts, setReport, setProfits, setServiceNames, toDate, setToDate, fromDate, setFromDate, setLineData, setTimeLabels, setLoading }: Props) => {

    const [selectedExivityServiceCategoryName, setSelectedExivityServiceCategoryName] = React.useState<string>("");
    const [selectedAccountName, setSelectedAccountName] = React.useState<string>("");
    const originalFromDate = new Date(Date.now() - 31 * 24 * 60 * 60 * 1000);
    const originalToDate = new Date();


    const handleServiceCategoryChange = (event: SelectChangeEvent<typeof selectedExivityServiceCategoryName>) => {
        setSelectedExivityServiceCategoryName(event.target.value);
        setSelectedExivityServiceCategory(exivityServiceCategories.find(serviceCategory => serviceCategory.name == event.target.value));
    };

    const handleAccountChange = (event: SelectChangeEvent<typeof selectedAccountName>) => {
        setSelectedAccountName(event.target.value);
        setSelectedAccount(exivityAccounts.find(account => account.name == event.target.value));
    };

    const handleFromDateChange = (date: Date | null) => {
        if (toDate != null && date != null && date <= toDate) {
            setFromDate(date)
        }
    }

    const handleToDateChange = (date: Date | null) => {
        if (fromDate != null && date != null && date >= fromDate) {
            setToDate(date)
        }
    }

    function isLineData(obj: any): obj is lineDataInterface[] {
        return (
            typeof obj === 'object' &&
            obj !== null &&
            'label' in obj[0] &&
            'data' in obj[0]
        );
    }

    const reset = () => {
        setSelectedAccount(undefined);
        setSelectedAccountName("");
        setSelectedExivityServiceCategory(undefined);
        setSelectedExivityServiceCategoryName("");
        setFromDate(originalFromDate);
        setToDate(originalToDate);
        setNoArguments(true);
        getReportsWithNoArguments(exivityServiceCategories);
    }

    const generateReport = () => {
        if (selectedExivityServiceCategory != undefined || selectedAccount != undefined) {
            setNoArguments(false);
            setLoading(true);

            axios.get(`/Report/Service/${fromDate?.toLocaleDateString('en-GB').split('/').reverse().join('')} && ${toDate?.toLocaleDateString('en-GB').split('/').reverse().join('')} && ${selectedExivityServiceCategory ? selectedExivityServiceCategory.serviceCategoryId : "%20"} && ${selectedAccount ? selectedAccount.accountId : "%20"}`).then((response: AxiosResponse) => {
                const originalArray = response.data
                if (originalArray.length != 0) {
                    setReport(originalArray)

                    const unitBasedSubtotalCharges = Object.values(originalArray.reduce((a: any, { serviceItemName, unitBasedSubtotalCharge }: any) => {
                        a[serviceItemName] = a[serviceItemName] || { serviceItemName, unitBasedSubtotalCharge: 0 };
                        a[serviceItemName].unitBasedSubtotalCharge += unitBasedSubtotalCharge;
                        return a;
                    }, {}));

                    const mappedValues = Object.values(originalArray.reduce((a: any, { serviceItemName, serviceCategoryId, unitBasedSubtotalCharge, totalCharge, totalCogs, totalNet }: any) => {
                        a[serviceItemName] = a[serviceItemName] || { serviceItemName, serviceCategoryIdunitBasedSubtotalCharge: 0, totalCharge: 0, totalCogs: 0, totalNet: 0 };
                        a[serviceItemName].serviceCategoryId = serviceCategoryId;
                        a[serviceItemName].unitBasedSubtotalCharge += unitBasedSubtotalCharge;
                        a[serviceItemName].totalCharge += totalCharge;
                        a[serviceItemName].totalCogs += totalCogs;
                        a[serviceItemName].totalNet += totalNet;
                        return a;
                    }, {}));


                    mappedValues.forEach((report: any) => {
                        const object = exivityServiceCategories.find((s1) => s1.serviceCategoryId == report.serviceCategoryId)
                        if (object != undefined) {
                            report.serviceTypeName = object.name;
                        }
                    })

                    setReport(mappedValues as Report[])

                    setServiceNames(unitBasedSubtotalCharges.map((value: any) => value.serviceItemName))
                    setProfits(mappedValues.map((value: any) => value.totalCharge))

                    //Create new array with service_id and total_charge 
                    const totalCharges = Object.values(originalArray.reduce((a: any, { serviceItemName, totalCharge }: any) => {
                        a[serviceItemName] = a[serviceItemName] || { serviceItemName, totalCharge: [] }
                        return { ...a, ...{ [serviceItemName]: { serviceItemName, totalCharge: a[serviceItemName].totalCharge.concat(totalCharge) } } }
                    }, {}))


                    totalCharges.forEach((res: any, index: number) => {
                        res.label = res.serviceItemName
                        res.data = res.totalCharge
                        res.borderColor = backgroundColors[index]
                        res.backgroundColor = backgroundColors[index]
                        delete res.serviceItemName
                        delete res.totalCharge
                    })

                    if (isLineData(totalCharges)) {
                        setLineData(totalCharges)
                    }

                    //Do the same for dates to get time stamps
                    const dates = originalArray.reduce((a: any, { day }: any) => {
                        a[day] = a[day] || { day: [] }
                        return { ...a, ...{ [day]: { day } } }
                    }, {})
                    const dateResult = Object.values(dates)

                    setTimeLabels(dateResult.map((date: any) => {
                        const year = date["day"].substring(0, 4)
                        const month = date["day"].substring(4, 6)
                        const day = date["day"].substring(6, 8)
                        const newDate = new Date(year, month - 1, day);
                        return newDate.toLocaleDateString('en-GB');
                    }))
                } else {
                    setReport([])
                    setServiceNames([])
                    setProfits([])
                    setLineData([])
                    setTimeLabels([])
                }
            }).then(() => {
                setLoading(false);
            })
        } else {
            setNoArguments(true);
            getReportsWithNoArguments(exivityServiceCategories)
        }
    }

    return (
        <>
            <Card sx={{ padding: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={4} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="From"
                                inputFormat="MM/dd/yyyy"
                                value={fromDate}
                                onChange={(date) => handleFromDateChange(date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="To"
                                inputFormat="MM/dd/yyyy"
                                value={toDate}
                                onChange={(date) => handleToDateChange(date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} >
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Select a service type</InputLabel>
                            <Select
                                defaultValue=""
                                value={selectedExivityServiceCategoryName}
                                onChange={handleServiceCategoryChange}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                            >
                                {exivityServiceCategories.map((serviceCategory) => (
                                    <MenuItem
                                        key={serviceCategory.serviceCategoryId}
                                        value={serviceCategory.name}
                                    >
                                        {serviceCategory.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Select a tenant</InputLabel>
                            <Select
                                defaultValue=""
                                value={selectedAccountName}
                                onChange={handleAccountChange}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                            >
                                {exivityAccounts.filter((acc1) => acc1.level == 1).map((account) => (
                                    <MenuItem
                                        key={account.accountId}
                                        value={account.name}
                                    >
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <Button variant="contained" color="success" sx={{ height: "100%", width: "100%" }} onClick={generateReport} disabled={toDate == null || fromDate == null}>
                            Generate Report
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="error" sx={{ height: "100%", width: "100%" }} onClick={reset} >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Divider />
        </>
    )
}

export default ReportHeader;

