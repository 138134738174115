export function dateConverter(a: string) {
  return a[8] + a[9] + "." + a[5] + a[6] + "." + a[2] + a[3];
}

export function getDayFromDate(a: string) {
  const d = new Date(a);
  const weekday = new Array(7);
  weekday[0] = "Mon ";
  weekday[1] = "Tue ";
  weekday[2] = "Wed ";
  weekday[3] = "Thu ";
  weekday[4] = "Fri ";
  weekday[5] = "Sat ";
  weekday[6] = "Sun ";

  return weekday[d.getDay() - 1];
}
