import { useTheme } from "@mui/material/styles";
import {
    ArcElement, CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import React from 'react';
import { Pie } from "react-chartjs-2";
import { backgroundColors } from "../../colors";


interface Props {
    serviceNames: string[];
    profits: number[];
}


ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
);

/**
 * Component for Pie diagram in the report
 * @param {string[]} serviceTypeNames List with serviceType names
 * @param {number[]} profits List with profit for related serviceType
 * @returns ReportPie
 */
const ReportPie = ({ serviceNames, profits }: Props) => {
    const theme = useTheme();

    const data = {
        labels: serviceNames,
        datasets: [
            {
                label: 'Profit in NOK',
                data: profits,
                backgroundColor: backgroundColors, 
                borderWidth: 1,
            },
        ],
    };

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Top Services',
                color: `${theme.palette.text.primary}`,
                font: {
                    size: 20
                },
            },
            legend: {
                display: false
            },
        },
        maintainAspectRatio: false
    }


    return (
        <Pie data={data} options={pieOptions} />
    )
}

export default ReportPie;