import { TextField } from "@mui/material";
import React from "react";

interface Props {
  inputValue: string;
  setInputValue: (str: string) => void;
}
/**
 * Searchbar for serviceOverviewContainer
 * @param {string} inputValue String used for holding user input
 * @param {SetStateAction} setInputValue Setter for inputValue string
 * @returns ServiceSearch
 */
const ServiceSearch = ({ inputValue, setInputValue }: Props) => {
  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      value={inputValue}
      onChange={handleInputChange}
      sx={{ width: "100%", rigth: "0" }}
      variant="filled"
      label="Search for service type or service"
      InputLabelProps={{
        style: {
          color: "black",
        },
      }}
      inputProps={{
        autoComplete: "off", // disable autocomplete and autofill
      }}
    />
  );
};

export default ServiceSearch;
