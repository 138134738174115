import PersonIcon from '@mui/icons-material/Person';
import {
    Box, Divider, ListItemButton,
    ListItemText
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Tenant } from "../../models/Tenant";
import { useTenant } from '../../contexts/TenantContext';



interface Props {
    tenant: Tenant;
}

/**
 * Clickable component for displaying tenent in TenantOverview
 * @param {Tenant} tenant 
 * @returns TenantCard
 */
const TenantCard = ({  tenant }: Props) => {
    const navigate = useNavigate();
    const {setSelectedTenant} = useTenant();

    
    const handleSelect = (tenant: Tenant) => {
        setSelectedTenant(tenant);
        navigate(`/tenant/${tenant.id}`, {
            state: {
                tenant: tenant,
            }
        })
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: "100%" }}>
                <ListItemButton
                    sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "space-evenly",
                    }}
                    alignItems="center"
                    onClick={() => handleSelect(tenant)}
                >
                    <PersonIcon />
                    <ListItemText primary={tenant.name}></ListItemText>
                </ListItemButton>
                <Divider />
            </Box>
        </Box>
    );
};

export default TenantCard;
