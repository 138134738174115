import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { get } from "./ajax";
import "./App.css";
import NewOfferPage from "./components/OfferComponents/NewOfferPage";
import OfferPage from "./components/OfferComponents/OfferPage";
import DashboardContainer from "./containers/DashboardContainer";
import EditServiceItemContainer from "./containers/EditServiceItemContainer";
import Layout from "./containers/Layout";
import Login from "./containers/Login";
import NewServiceItemContainer from "./containers/NewServiceItemContainer";
import NotFound from "./containers/NotFound";
import OfferContainer from "./containers/OfferContainer";
import Reports from "./containers/Reports";
import Roles from "./containers/Roles";
import ServiceItemContainer from "./containers/ServiceItemContainer";
import ServiceOverviewContainer from "./containers/ServiceOverviewContainer";
import TenantOverview from "./containers/TenantOverview";
import TenantPage from "./containers/TenantPage";
import { AuthProvider } from "./contexts/AuthContext";
import { useIsAuth } from "./contexts/IsAuthenticatedContext";
import { TenantProvider } from "./contexts/TenantContext";
import { ServiceType } from "./models/ServiceType";
import { darkTheme } from "./themes/DarkTheme";
import { lightTheme } from "./themes/LightTheme";

/**
 *
 * @returns Application
 */
function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [darkMode, setDarkMode] = useState(false);
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const { isAuth } = useIsAuth();
  const { accounts } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      get("/ServiceType").then((response) => {
        setServiceTypes(response.data);
      });
    }
  }, [isAuth]);

  useEffect(() => {
    if (localStorage.getItem("DarkTheme") === null) {
      setDarkMode(prefersDarkMode);
    } else {
      setDarkMode(localStorage.getItem("DarkTheme") === "true");
    }
  }, [prefersDarkMode]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <AuthProvider>
                  {isAuthenticated ? (
                    <Layout
                      isDarkMode={darkMode}
                      updateDarkmode={(bool: boolean) => {
                        localStorage.setItem("DarkTheme", bool.toString());
                        setDarkMode(bool);
                      }}
                      showSidebar={showSidebar}
                      setShowSidebar={setShowSidebar}
                      accounts={accounts}
                    />
                  ) : (
                    <Navigate to="/login" />
                  )}
                </AuthProvider>
              }
            >
              <Route path="/" element={<DashboardContainer />} />

              <Route
                path="service/overview"
                element={
                  <ServiceOverviewContainer serviceTypes={serviceTypes} />
                }
              />
              <Route
                path="service/overview/:serviceTypeID/:serviceItemID/editService"
                element={
                  <EditServiceItemContainer
                    serviceTypes={serviceTypes}
                    setServiceTypes={setServiceTypes}
                  ></EditServiceItemContainer>
                }
              />
              <Route
                path="service/overview/:serviceTypeID/:serviceName"
                element={
                  <ServiceItemContainer
                    setServiceTypes={setServiceTypes}
                    serviceTypes={serviceTypes}
                  ></ServiceItemContainer>
                }
              />
              <Route
                path="service/overview/:serviceTypeID/newService"
                element={
                  <NewServiceItemContainer
                    setServiceTypes={setServiceTypes}
                    serviceTypes={serviceTypes}
                  ></NewServiceItemContainer>
                }
              />
              <Route
                path="tenant/overview"
                element={
                  <TenantProvider>
                    <TenantOverview />
                  </TenantProvider>
                }
              />
              <Route
                path="tenant/:tenantID"
                element={
                  <TenantProvider>
                    <TenantPage />
                  </TenantProvider>
                }
              />
              <Route path="offer/overview" element={<OfferContainer />} />
              <Route
                path="offer/newOffer"
                element={
                  <NewOfferPage
                    accounts={accounts}
                    serviceTypes={serviceTypes}
                  ></NewOfferPage>
                }
              />
              <Route
                path="offer/:offerID"
                element={<OfferPage serviceTypes={serviceTypes} />}
              />
              <Route path="reports" element={<Reports />} />
              <Route path="admin/roles" element={<Roles />} />
            </Route>
            <Route
              path="/login"
              element={
                <Login
                  isDarkMode={darkMode}
                  updateDarkmode={(bool: boolean) => setDarkMode(bool)}
                />
              }
            />
            <Route
              path="*"
              element={
                <NotFound isDarkMode={darkMode} updateDarkMode={setDarkMode} />
              }
            />
          </Routes>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
