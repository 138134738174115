import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  AlertColor,
  Box,
  Button, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { remove } from "../../ajax";
import { useAuth } from "../../contexts/AuthContext";
import { dateConverter } from "../../hooks/DateConverter";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";
import SuccessAlert from "../Dialogs/SuccessAlert";
import ServieItemRateTable from "./ServieItemRateTable";

interface Props {
  serviceItem: ServiceItem;
  serviceType: ServiceType;
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
  serviceTypes: ServiceType[];
}

/**
 * Container for displaying information from service and edit/delete options
 * @param {ServiceType} serviceType Chosen serviceType
 * @param {serviceItem} serviceItem Chosen serviceItem
 * @param {serviceTypes[]} serviceTypes All servicetypes (used to update list without reloading page)
 * @param {SetStateAction} setServiceTypes Set all servicetypes (used to update list without reloading page)
 * @returns ServiceInformation
 */
const ServiceInformation = ({
  serviceType,
  serviceItem,
  serviceTypes,
  setServiceTypes,
}: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [severity, setseverity] = useState<AlertColor>();
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const { tags } = useAuth();

  //Delete funciton. Deleting both in database and in app
  const onProceedClick = () => {
    setOpenLoadingModal(true);
    remove(
      `/ServiceType/ServiceItem/${serviceItem.serviceItemId}&&${serviceItem.exivityId}`
    )
      .then(() => {
        const serviceTypesList: ServiceType[] = serviceTypes;
        const typeIndex = serviceTypesList.findIndex(
          (obj) => obj.serviceTypeId == serviceType.serviceTypeId
        );
        const itemIndex = serviceTypesList[typeIndex].serviceItems.findIndex(
          (obj) => obj.serviceItemId == serviceItem.serviceItemId
        );
        serviceTypesList[typeIndex].serviceItems.splice(itemIndex, 1);
        setServiceTypes(serviceTypesList);
        setOpenLoadingModal(false);
        setSuccess(true);
        setseverity("success");
      })
      .catch(() => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setseverity("error");
      });
  };
  const onDelete = () => {
    setOpen(true);
    setSuccess(false);
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <ServieItemRateTable serviceItem={serviceItem} />
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Typography>
            {" Last updated: (" +
              dateConverter(serviceItem.rate.rateHistory[0].dateChanged) +
              ")"}{" "}
          </Typography>
        </Box>
      </Box>
      <SuccessAlert
        text={
          severity == "success"
            ? "You successfully deleted the service"
            : "Something has gone wrong"
        }
        setOpen={setSuccess}
        open={success}
        severity={severity}
      />
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={onDelete}
          variant="contained"
          color="error"
          fullWidth
          sx={{ borderRadius: "0" }}
          endIcon={<DeleteForeverIcon />}
          disabled={
            tags.findIndex((tag) => tag.scope == "serviceItem:delete") == -1
          }
        >
          {tags.findIndex((tag) => tag.scope == "serviceItem:delete") == -1
            ? "   No permission to "
            : ""}
          Delete
        </Button>
        <Button
          onClick={() =>
            navigate(
              `/service/overview/${serviceType.serviceTypeId}/${serviceItem.serviceItemId}/editService`
            )
          }
          variant="contained"
          color="success"
          fullWidth
          sx={{ borderRadius: "0" }}
          endIcon={<EditIcon />}
          disabled={
            tags.findIndex((tag) => tag.scope == "serviceItem:put") == -1
          }
        >
          {tags.findIndex((tag) => tag.scope == "serviceItem:put") == -1
            ? "   No permission to "
            : ""}
          Edit
        </Button>

        <LoadingModal text="Updating Exivity" open={openLoadingModal} />
        <ProceedDialog
          onProceedAction={onProceedClick}
          text="Are you sure you want to delete the service?"
          setOpen={setOpen}
          open={open}
        />
      </Box>
    </>
  );
};

export default ServiceInformation;
