import {
  AlertColor,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ServiceType } from "../../models/ServiceType";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import SuccessAlert from "../Dialogs/SuccessAlert";
import { post, remove } from "../../ajax";
import { Contact } from "../../models/Contact";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ProceedDialog from "../Dialogs/ProceedDialog";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useAuth } from "../../contexts/AuthContext";

interface Props {
  serviceType: ServiceType;
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}

/**
 * Component for contact information and links related to the serviceType. Option to add or delete contacts if the user has permission
 * @param {ServiceType} serviceType Chosen ServiceType
 * @param {ServiceType[]} serviceTypes List off all ServiceTypes
 * @param {SetStateAction} setServiceTypes Setter for all serviceTypes (used to set servicetypes without reloading page)
 * @returns ServiceContacts
 */
const ServiceContacts = ({
  serviceType,
  serviceTypes,
  setServiceTypes,
}: Props) => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [severity, setSeverity] = useState<AlertColor>();
  const [success, setSuccess] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteid] = useState("");
  const [sucessText, setSucsessText] = useState("");
  const { tags } = useAuth();

  const addContact = () => {
    if (role == "" || phoneNumber == "" || email == "") {
      setSeverity("error");
      setAlertText("You need to fill alle the fields");
      setSuccess(true);
    } else {
      const postItem: Contact = {
        email: email,
        phoneNumber: phoneNumber,
        role: role,
        serviceTypeId: serviceType.serviceTypeId,
      };
      post("/ServiceType/Contact", postItem)
        .then((response) => {
          setSuccess(false);
          const serviceTypesList: ServiceType[] = serviceTypes;
          const typeIndex = serviceTypesList.findIndex(
            (obj) => obj.serviceTypeId == serviceType.serviceTypeId
          );
          const newContact: Contact = response.data;
          serviceTypesList[typeIndex].contacts.push(newContact);
          setServiceTypes(serviceTypesList);

          setSuccess(true);
          setSeverity("success");
          setSucsessText("You successfully created the contact");
        })
        .catch(() => {
          setSuccess(true);
          setSeverity("error");
          setAlertText("Something has gone wrong");
        });
    }
  };

  const handleDelete = (id: string) => {
    setSuccess(false);
    setOpen(true);
    setDeleteid(id);
  };

  const deleteContact = () => {
    remove(`ServiceType/Contact/${deleteId}`)
      .then(() => {
        const serviceTypesList: ServiceType[] = serviceTypes;
        const typeIndex = serviceTypesList.findIndex(
          (obj) => obj.serviceTypeId == serviceType.serviceTypeId
        );
        serviceTypesList[typeIndex].contacts.filter(
          (contact: Contact) => contact.contactId != deleteId
        );

        const finalContacts: Contact[] = serviceTypesList[
          typeIndex
        ].contacts.filter((contact: Contact) => contact.contactId != deleteId);
        serviceTypesList[typeIndex].contacts = finalContacts;

        setServiceTypes(serviceTypesList);
        setSuccess(true);
        setSeverity("success");
        setSucsessText("You successfully deleted the contact");
      })
      .catch(() => {
        setSuccess(true);
        setSeverity("error");
        setAlertText("Something has gone wrong");
      });
  };

  const getContacts = () => {
    if (serviceType.contacts.length == 0) {
      return (
        <Typography sx={{ textAlign: "left", marginLeft: "10%" }}>
          This service has no contacts
        </Typography>
      );
    } else {
      return (
        <List sx={{ marginLeft: "10%" }}>
          {serviceType.contacts.map((contact, i) => {
            return (
              <ListItem
                sx={{
                  display: "flex",
                }}
                key={i}
              >
                <Typography>{contact.role + ": "}</Typography>
                <Link href={"mailto:" + contact.email}>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {" "}
                    {contact.email}
                  </Typography>
                </Link>
                <EmailIcon />
                {contact.phoneNumber != null ? (
                  <>
                    <Typography sx={{ marginInline: "10px" }}>
                      {contact.phoneNumber}
                    </Typography>
                    <LocalPhoneIcon />{" "}
                  </>
                ) : (
                  <></>
                )}
                {tags.findIndex((tag) => tag.scope == "contact:delete") ==
                -1 ? (
                  <></>
                ) : (
                  <IconButton
                    onClick={() => {
                      if (contact.contactId != undefined) {
                        handleDelete(contact.contactId);
                      }
                    }}
                    sx={{ marginLeft: "10px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}

                <ProceedDialog
                  open={open}
                  setOpen={setOpen}
                  text={"Are you sure you want to delete this contact?"}
                  onProceedAction={deleteContact}
                />
              </ListItem>
            );
          })}
        </List>
      );
    }
  };

  const getLinks = () => {
    const linkNames: string[] = [
      "Sales Material",
      "Service Description",
      "Technical Documentation",
    ];
    const allLinks: string[] = [];
    const links: string[] = [];
    allLinks.push(serviceType.links.salesMaterial);
    allLinks.push(serviceType.links.serviceDescription);
    allLinks.push(serviceType.links.technicalDocumentation);

    allLinks.forEach((link: string) => {
      if (link != "") links.push(link);
    });
    if (links.length === 0) {
      return (
        <Typography
          sx={{
            textAlign: "left",
            marginLeft: "10%",
            marginTop: "10px",
            padding: "10px",
          }}
        >
          This service has no links
        </Typography>
      );
    } else {
      return (
        <List sx={{ marginLeft: "10%" }}>
          {links.map((link, i) => {
            return (
              <ListItem
                sx={{
                  display: "flex",
                }}
                key={i}
              >
                <Typography>{linkNames[i]}</Typography>
                <Link href={link}>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    {" "}
                    Go to link
                  </Typography>
                </Link>

                <LinkIcon />
              </ListItem>
            );
          })}
        </List>
      );
    }
  };
  return (
    <>
      <Divider />
      <Typography
        sx={{ textAlign: "left", marginLeft: "5%", padding: "10px" }}
        variant="h6"
      >
        Contacts:
      </Typography>
      <Divider />

      {getContacts()}
      {tags.findIndex((tag) => tag.scope == "contact:post") == -1 ? (
        <></>
      ) : (
        <>
          <Divider />
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              flexDirection: { lg: "row", xs: "column" },
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <Typography sx={{ paddingTop: "10px" }}>Add contact:</Typography>
            <TextField
              sx={{ minWidth: "20%" }}
              autoComplete="off"
              type="text"
              variant="outlined"
              label="Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
            <TextField
              autoComplete="off"
              type="Email"
              variant="outlined"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              autoComplete="off"
              type="text"
              variant="outlined"
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />

            <Button onClick={addContact} variant="contained">
              <AddIcon />
            </Button>
          </Box>
        </>
      )}
      <SuccessAlert
        text={severity == "success" ? sucessText : alertText}
        setOpen={setSuccess}
        open={success}
        severity={severity}
      />
      <Divider />
      <Typography
        sx={{ textAlign: "left", marginLeft: "5%", padding: "10px" }}
        variant="h6"
      >
        Links:
      </Typography>
      <Divider />
      {getLinks()}
    </>
  );
};

export default ServiceContacts;
