import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance
      .logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      })
      .then(() => {
        localStorage.clear();
      });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        handleLogout();
      }}
    >
      Sign Out
    </Button>
  );
};
