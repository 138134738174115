import { useMsal } from "@azure/msal-react";
import { makeStyles } from "@material-ui/styles";
import SaveIcon from "@mui/icons-material/Save";
import {
  AlertColor,
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { put } from "../../ajax";
import { useForm } from "../../hooks/useFrom";
import { Rate } from "../../models/Rate";
import { RateHistory } from "../../models/RateHistory";
import { RatePutResponse } from "../../models/RatePutResponse";
import { ServiceType } from "../../models/ServiceType";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";
import SuccessAlert from "../Dialogs/SuccessAlert";

interface Props {
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}
const useStyle = makeStyles(() => ({
  root: {
    "& .MuiFormControl-root": {
      margin: "5px",
      marginTop: "10px",
      width: "45%",
    },
    "& .MuiAutocomplete-root": {
      display: "inline",
      flexWrap: "wrap",
    },
  },
}));

/**
 * Component for editing serviceItem rate
 * @param {ServiceType[]} serviceTypes Used to get wanted serviceItem and to update serviceType
 * @param {SetStateAction} setServiceTypes Used to update serviceTypes
 * @returns EditRate
 */

const EditRate = ({ serviceTypes, setServiceTypes }: Props) => {
  const { accounts } = useMsal();
  const email = accounts[0].username;
  const classes = useStyle();
  const url = window.location.pathname;
  const urlArr = url.split("/");
  const id = urlArr[3];
  const category = serviceTypes.find(
    (serviceType) => serviceType.serviceTypeId == id
  );
  const item = category?.serviceItems.find(
    (item) => item.serviceItemId == urlArr[4]
  );

  const intervalOptions = [
    "NEVER",
    "INDUVIDUALLY",
    "SECOND",
    "MINUTE",
    "HOUR",
    "DAY",
    "WEEK",
    "MONTH",
    "YEAR",
  ];

  let initialState = {
    reason: "",
    name: "",
    description: "",
    serviceTypeId: category?.serviceTypeId,
    price: 0,
    cogs: 0,
    priceUnit: "",
    currency: "",
    billingInterval: 0,
  };

  if (item != undefined) {
    initialState = {
      reason: "",
      name: item.name,
      description: item.description,
      serviceTypeId: category?.serviceTypeId,
      price: item.rate.price,
      cogs: item.rate.cogs,
      priceUnit: item.rate.priceUnit,
      currency: item.rate.currency,
      billingInterval: item.rate.billingInterval,
    };
  }
  // getting the event handlers from our custom hook
  const { onChange, onSubmit, values } = useForm(
    addServiceItemCallback,
    initialState
  );

  // a submit function that will execute upon form submission
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [errorAlertText, setErrorAlertText] = useState("");
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const onProceedClick = () => {
    const { price, cogs, billingInterval } = values;
    values.price = Number(price);
    values.cogs = Number(cogs);
    values.billingInterval = Number(billingInterval);
    const putItem = {
      rateId: item?.rateId,
      price: values.price,
      cogs: values.cogs,
      priceUnit: values.priceUnit,
      currency: values.currency,
      billingInterval: values.billingInterval,
      reason: values.reason,
      userEmail: email,
      exivityId: item?.rate.exivityId,
      serviceItemExivityId: item?.exivityId,
    };
    setOpenLoadingModal(true);

    put("/ServiceType/ServiceItem/Rate", putItem)
      .then((response) => {
        const serviceRateResponse: RatePutResponse = response.data;
        const newRateHistoryItem: RateHistory = {
          rateHistoryId: "temp",
          rateId: serviceRateResponse.rateId,
          dateChanged: serviceRateResponse.date,
          toPrice: serviceRateResponse.price,
          toCogs: serviceRateResponse.cogs,
          toPriceUnit: serviceRateResponse.priceUnit,
          toCurrency: serviceRateResponse.currency,
          toBillingInterval: serviceRateResponse.billingInterval,
          reason: values.reason,
          userEmail: email,
        };

        const serviceTypesList: ServiceType[] = serviceTypes;
        const typeIndex = serviceTypesList.findIndex(
          (obj) => obj.serviceTypeId == id
        );

        const itemIndex = serviceTypesList[typeIndex].serviceItems.findIndex(
          (obj) => obj.serviceItemId == urlArr[4]
        );
        const oldRateHistory: RateHistory[] =
          serviceTypesList[typeIndex].serviceItems[itemIndex].rate.rateHistory;
        oldRateHistory.push(newRateHistoryItem);

        const serviceRateObj: Rate = {
          exivityId: serviceRateResponse.exivityId,
          billingInterval: serviceRateResponse.billingInterval,
          cogs: serviceRateResponse.cogs,
          currency: serviceRateResponse.currency,
          price: serviceRateResponse.price,
          priceUnit: serviceRateResponse.priceUnit,
          rateHistory: oldRateHistory,
          rateId: serviceRateResponse.rateId,
        };

        serviceTypesList[typeIndex].serviceItems[itemIndex].rate =
          serviceRateObj;
        setServiceTypes(serviceTypesList);
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("success");
      })
      .catch(() => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("error");
        setErrorAlertText("Something has gone wrong");
      });
  };

  async function addServiceItemCallback() {
    if (values.reason == "" || values.price == 0 || values.cogs == 0) {
      setSuccess(true);
      setSeverity("error");
      setErrorAlertText(
        "You have to fill out all the fields before editing the rate!"
      );
    } else {
      setOpen(true);
      setSuccess(false);
    }
  }

  let intervalIndex = 0;
  if (item != undefined) intervalIndex = item?.rate.billingInterval;

  return (
    <React.Fragment>
      <Divider />
      <form autoComplete="on" onSubmit={onSubmit}>
        <FormControl focused fullWidth={true} className={classes.root}>
          <Box>
            <TextField
              autoComplete="off"
              fullWidth={true}
              defaultValue={""}
              type="text"
              variant="outlined"
              label="Reason for edit"
              name="reason"
              onChange={onChange}
            />
            <TextField
              defaultValue={intervalOptions[intervalIndex]}
              name="billingInterval"
              select
              label="Billing Interval per:"
              onChange={onChange}
            >
              {intervalOptions.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth={true}
              defaultValue={item?.rate.price}
              variant="outlined"
              label="Price for consumer"
              autoComplete="off"
              name="price"
              onChange={onChange}
            />

            <TextField
              defaultValue={item?.rate.priceUnit}
              name="priceUnit"
              variant="outlined"
              autoComplete="off"
              label="Price Unit"
              onChange={onChange}
            />

            <TextField
              defaultValue={item?.rate.cogs}
              name="cogs"
              onChange={onChange}
              type="number"
              variant="outlined"
              label="Cogs"
              autoComplete="off"
            />

            <TextField
              defaultValue={item?.rate.currency}
              name="currency"
              variant="outlined"
              autoComplete="off"
              label="Currency"
              onChange={onChange}
            />

            <SuccessAlert
              text={
                severity == "success"
                  ? "You successfully updated the rate"
                  : errorAlertText
              }
              setOpen={setSuccess}
              open={success}
              severity={severity}
              buttonText="Go to service"
            />

            <Button
              sx={{ marginTop: "10px" }}
              fullWidth={true}
              color="success"
              variant="contained"
              endIcon={<SaveIcon />}
              type="submit"
            >
              {" "}
              Edit Rate{" "}
            </Button>
            <LoadingModal open={openLoadingModal} text="Updating rate" />

            <ProceedDialog
              onProceedAction={onProceedClick}
              text="Are you sure you want to edit the rate?"
              setOpen={setOpen}
              open={open}
            />
          </Box>
        </FormControl>
      </form>
    </React.Fragment>
  );
};

export default EditRate;
