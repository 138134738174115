import React, { createContext, useContext, useState } from "react";

interface IsAuthenticatedProps {
  isAuth: boolean;
  login: () => void;
}

const isAuthenticatedContext = createContext<IsAuthenticatedProps>(
  {} as IsAuthenticatedProps
);

export const useIsAuth = () => {
  return useContext(isAuthenticatedContext);
};

export function IsAuthenticatedProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const login = () => {
    setIsAuth(true);
  };

  return (
    <isAuthenticatedContext.Provider value={{ isAuth: isAuth, login: login }}>
      {children}
    </isAuthenticatedContext.Provider>
  );
}
