import { AccountInfo } from "@azure/msal-browser";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import React, { useLayoutEffect, useRef } from "react";
import { SignOutButton } from "../ActionButtons/SignOutButton";

const useStyles = makeStyles({
  Menu: {
    marginTop: "60px",
    "& div": {
      width: "23rem",
      marginRight: "20px",
      borderRadius: "0",
      padding: "5px",
    },
  },
});

interface Props {
  graphData: AccountInfo;

  anchorEl: null | HTMLElement;
  setAnchorEl: (element: null | HTMLElement) => void;
}

/**
 * Component for loggin out and see profile information
 * @param {AccountInfo} graphData Used to display profile information
 * @param {HTMLElement} anchorEl Used for position of menu
 * @param {function} setAnchorEl Used to set position of menu
 * @returns ProfileMenu
 */

const ProfileMenu = ({ graphData, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();
  const menuRef = useRef<HTMLDivElement>(null);

  const getCredentials = () => {
    if (graphData != undefined && graphData.name != undefined) {
      const credentials = graphData.name
        .split(" ")
        .map((word) => word[0])
        .join("");
      return credentials;
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useLayoutEffect(() => {
    const handler = (event: any) => {
      if (menuRef.current != null) {
        if (menuRef.current == event.target) {
          setAnchorEl(null);
        }
      }
    };
    if (anchorEl != null) {
      document.addEventListener("mousedown", handler);
    }
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [anchorEl]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      ref={menuRef}
      className={classes.Menu}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Typography>Atea Pricing Calcutator</Typography>
        <SignOutButton></SignOutButton>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Avatar style={{ width: "100px", marginRight: "15%" }} alt="Remy Sharp">
          {getCredentials()}
        </Avatar>
        <div style={{ flexGrow: "1" }}>
          <b>
            <Typography variant="inherit">
              {graphData ? graphData.name : <></>}
            </Typography>
          </b>
          <Typography variant="subtitle2">
            {graphData ? graphData.username : <></>}
          </Typography>
        </div>
      </div>
    </Menu>
  );
  return <React.Fragment>{renderMenu}</React.Fragment>;
};

export default ProfileMenu;
