import React, { useEffect, useState } from "react";
import { ServiceType } from "../../models/ServiceType";
import {
  AlertColor,
  Box,
  Button,
  Divider,
  IconButton,
  ListSubheader,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { PacmanLoader } from "react-spinners";
import PageHeader from "../PageComponents/PageHeader";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { ServiceItem } from "../../models/ServiceItem";
import ClearIcon from "@mui/icons-material/Clear";
import generatePDF from "../../hooks/GeneratePdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import OfferServiceSearch from "./OfferServiceSearch";
import { AccountInfo } from "@azure/msal-browser";
import { errorConverter } from "../../hooks/errorConverter";
import { post } from "../../ajax";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";
import SuccessAlert from "../Dialogs/SuccessAlert";
import AddIcon from "@mui/icons-material/Add";

interface Props {
  serviceTypes: ServiceType[];
  accounts: AccountInfo[];
}
/**
 * Compnent to add new offer
 * @param {ServiceType[]} serviceTypes List of all serviceTypes. Used in OfferServiceSearch
 * @param {AccountInfo[]} accounts List of accounts. Used to get creator email for the offer
 * @returns Page for creating offer and/or generatinig a PDF document.
 */

const NewOfferPage = ({ serviceTypes, accounts }: Props) => {
  const [mounted, setMounted] = useState(true);
  const [chosenServiceTypes, setChosenServiceTypes] = useState<ServiceType[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [success, setSuccess] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const [offerName, setOfferName] = useState("");
  const [errorAlertText, setErrorAlertText] = useState("");

  useEffect(() => {
    if (serviceTypes != undefined) {
      setMounted(true);
    }
  }, []);

  const onProceedClick = () => {
    const finalOffer = {
      name: offerName,
      offerItems: chosenServiceTypes,
      creator: accounts[0].username,
    };

    setOpenLoadingModal(true);
    post("/Offer", finalOffer)
      .then(() => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("success");
      })
      .catch((error) => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("error");
        setErrorAlertText(errorConverter(error.response.data));
      });
  };

  const handleRemoveItemClick = (param1: ServiceType, param2: ServiceItem) => {
    const serviceIndex = chosenServiceTypes.findIndex(
      (service) => service.serviceTypeId === param1.serviceTypeId
    );
    const serviceItemIndex = chosenServiceTypes[
      serviceIndex
    ].serviceItems.findIndex(
      (item) => item.serviceItemId === param2.serviceItemId
    );
    const tempList: ServiceItem[] =
      chosenServiceTypes[serviceIndex].serviceItems;
    tempList.splice(serviceItemIndex, 1);

    const tempServiceList: ServiceType[] = chosenServiceTypes;
    tempServiceList[serviceIndex].serviceItems = tempList;
    setChosenServiceTypes([...tempServiceList]);
  };

  const handleRemoveServiceType = (serviceType: ServiceType) => {
    setChosenServiceTypes(
      chosenServiceTypes.filter(
        (service) => service.serviceTypeId !== serviceType.serviceTypeId
      )
    );
  };

  const onPriceChange = (
    value: string,
    typeIndex: number,
    itemIndex: number
  ) => {
    const temp = [...chosenServiceTypes];
    temp[typeIndex].serviceItems[itemIndex].rate.price = Number(value);
    setChosenServiceTypes(temp);
  };

  const addNewServiceType = (list: ServiceType[]) => {
    setChosenServiceTypes(list);
  };
  return (
    <>
      <PageHeader
        title="Create a new offer"
        subtitle="Choose service types and edit the prices"
        icon={<LocalOfferIcon />}
      />
      <OfferServiceSearch
        serviceTypes={serviceTypes}
        chosenServiceTypes={chosenServiceTypes}
        setChosenServiceTypes={addNewServiceType}
      />
      {mounted ? (
        <Box>
          <Paper elevation={4}>
            <Box sx={{ width: "100%" }}>
              {chosenServiceTypes.length > 0 ? (
                chosenServiceTypes.map((serviceType, typeIndex) => {
                  return (
                    <React.Fragment key={serviceType.serviceTypeId}>
                      <ListSubheader
                        color="inherit"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ padding: "10px" }}>
                          {" "}
                          {serviceType.friendlyName}
                        </Typography>
                        <IconButton
                          onClick={() => handleRemoveServiceType(serviceType)}
                        >
                          <ClearIcon color="error" />
                        </IconButton>
                      </ListSubheader>
                      <Divider />
                      <Table
                        sx={{ width: "90%" }}
                        key={serviceType.serviceTypeId}
                      >
                        <tbody>
                          {serviceType.serviceItems.map(
                            (serviceItem, itemIndex) => {
                              return (
                                <TableRow
                                  sx={{ marginLeft: "10%", width: "20%" }}
                                  key={serviceItem.serviceItemId}
                                >
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    {serviceItem.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    <TextField
                                      autoComplete="off"
                                      label="Price"
                                      variant="filled"
                                      onChange={(e) =>
                                        onPriceChange(
                                          e.target.value,
                                          typeIndex,
                                          itemIndex
                                        )
                                      }
                                      defaultValue={serviceItem.rate.price}
                                    ></TextField>
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    {serviceItem.rate.currency}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    {serviceItem.rate.priceUnit}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        handleRemoveItemClick(
                                          serviceType,
                                          serviceItem
                                        );
                                      }}
                                    >
                                      <ClearIcon color="error" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  );
                })
              ) : (
                <Box sx={{ width: "100%", padding: "5%" }}>
                  <Typography>
                    Choose services from the search field
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              <Divider />
              <TextField
                label="Enter a name for the Offer"
                variant="filled"
                value={offerName}
                onChange={(e) => setOfferName(e.target.value)}
                sx={{ width: "60%", marginBottom: "3%", marginTop: "3%" }}
              ></TextField>
            </Box>
            <SuccessAlert
              text={
                severity == "success"
                  ? "You successfully created the offer"
                  : errorAlertText
              }
              setOpen={setSuccess}
              open={success}
              severity={severity}
            />
            <Box sx={{ display: "flex", justifyContent: "evenly" }}>
              <Button
                variant="contained"
                color="warning"
                fullWidth
                disabled={offerName == ""}
                sx={{ borderRadius: "0" }}
                endIcon={<PictureAsPdfIcon />}
                onClick={() =>
                  generatePDF(
                    chosenServiceTypes,
                    offerName,
                    accounts[0].username
                  )
                }
              >
                Generate PDF
              </Button>
              <Button
                variant="contained"
                color="success"
                disabled={offerName == ""}
                fullWidth
                sx={{ borderRadius: "0" }}
                endIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                Create
              </Button>

              <LoadingModal open={openLoadingModal} text="Creating offer" />

              <ProceedDialog
                onProceedAction={onProceedClick}
                text="Are you sure you want to create the offer?"
                setOpen={setOpen}
                open={open}
              />
            </Box>
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "15vw",
          }}
        >
          <PacmanLoader size={40}></PacmanLoader>
        </Box>
      )}
    </>
  );
};

export default NewOfferPage;
