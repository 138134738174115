import { AlertColor, Tooltip, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { get, put } from "../../ajax";
import { errorConverter } from "../../hooks/errorConverter";
import { Tag } from "../../models/Tag";
import { User } from "../../models/User";
import LoadingModal from "../Dialogs/LoadingModal";
import SuccessAlert from "../Dialogs/SuccessAlert";

interface Props {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  tags: Tag[];
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
}

function not(a: Tag[], b: Tag[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Tag[], b: Tag[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: Tag[], b: Tag[]) {
  return [...a, ...not(b, a)];
}

/**
 *
 * @param {User} user Chosen user
 * @param {SetStateAction} setUser Used to set chosen user
 * @param {Tag[]} tags List with permission tags
 * @param {User[]} users List of all users used in UserSearch
 * @param {SetStateAction} setUsers Used to update users
 * @returns UserInformation
 */

const UserInformation = ({ user, setUser, tags, users, setUsers }: Props) => {
  const [checked, setChecked] = React.useState<Tag[]>([]);
  const [left, setLeft] = React.useState<Tag[]>([]);
  const [right, setRight] = React.useState<Tag[]>([]);
  const [originalTags, setOriginalTags] = React.useState<Tag[]>([]);
  const [severity, setSeverity] = React.useState<AlertColor>();
  const [success, setSuccess] = React.useState<boolean>(false);
  const [successText, setSuccessText] = React.useState<string>("");
  const [errorAlertText, setErrorAlertText] = React.useState<string>("");
  const [openLoadingModal, setOpenloadingModal] =
    React.useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    setRight([]);
    setLeft([]);
    let isMounted = true;
    get(`User/Tag/${user.oid}`).then((response: AxiosResponse) => {
      if (isMounted) {
        setRight(response.data);
        setOriginalTags(response.data);
        const left = tags.filter(
          (tag1) => !response.data.find((tag2: Tag) => tag1.tagId == tag2.tagId)
        );
        setLeft(left);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [user]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: Tag) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: Tag[]) => intersection(checked, items).length;

  const handleToggleAll = (items: Tag[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const onUpdateClick = () => {
    const item = {
      accesses: right,
      oid: user.oid,
    };
    setOpenloadingModal(true);
    put("/User", item)
      .then((response: AxiosResponse) => {
        const userList = users;
        const userIndex = users.findIndex((usr) => usr.oid == user.oid);
        userList[userIndex].tags = response.data;
        setUsers(userList);
        setUser(userList[userIndex]);
      })
      .then(() => {
        setOpenloadingModal(false);
        setSuccess(true);
        setSeverity("success");
        setSuccessText("Succesfully updated permissions");
      })
      .catch((error) => {
        setOpenloadingModal(false);
        setSuccess(true);
        setSeverity("error");
        setErrorAlertText(errorConverter(error.response.data));
      });
  };

  const onResetClick = () => {
    setRight(originalTags);
    const left = tags.filter(
      (tag1) => !originalTags.find((tag2: Tag) => tag1.tagId == tag2.tagId)
    );
    setLeft(left);
  };
  const customList = (title: React.ReactNode, items: Tag[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 500,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: Tag) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.tagId}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <Tooltip title={value.scope}>
                <ListItemText id={labelId} primary={value.description} />
              </Tooltip>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <Grid
        sx={{ height: "65%" }}
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>{customList("Roles available", left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
      
            <Tooltip
              title={
                leftChecked.length === 0
                  ? "No new roles selected"
                  : "Click to give selected roles"
              }
            >
              <span>
                <Button
                  sx={{ my: 0.5, color: `${theme.palette.text.primary}` }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                >
                  &gt;
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              title={
                rightChecked.length === 0
                  ? "No roles to remove selected"
                  : "Click to remove selected roles"
              }
            >
              <span>
                <Button
                  sx={{ my: 0.5, color: `${theme.palette.text.primary}` }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                >
                  &lt;
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>{customList("Roles this user has", right)}</Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            onClick={onResetClick}
            sx={{ width: "100%" }}
            variant="contained"
            color="error"
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={onUpdateClick}
            sx={{ width: "100%" }}
            variant="contained"
            color="success"
          >
            Update
          </Button>
        </Grid>
      </Grid>
      <SuccessAlert
        text={severity == "success" ? successText : errorAlertText}
        setOpen={setSuccess}
        open={success}
        severity={severity}
      />
      <LoadingModal
        text="Updating permissions for selected user"
        open={openLoadingModal}
      />
    </>
  );
};

export default UserInformation;
