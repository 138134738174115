import { makeStyles } from "@material-ui/styles";
import { Autocomplete, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../models/Page";
import { ServiceItem } from "../../models/ServiceItem";
import { Tenant } from "../../models/Tenant";


const useStyles = makeStyles({
  TextField: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#202020",
  },
  multilineColor: {
    color: "#202020",
  },
});

interface Props {
  serviceItems: ServiceItem[];
  tenants: Tenant[];
}

/**
 * Component for search field in NavBar. Used to search on different pages in website
 * @param {ServiceItem[]} serviceItems Used to display in serviceItems in search list
 * @param { Tenant[]} tenants Used to display in tenatns in search list
 * @returns NavSearch
 */

const NavSearch = ({ serviceItems, tenants }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>("");

  const handleSearchClick = (event: any, value: ServiceItem | null | Page | Tenant) => {
    if (isPage(value)) {
      navigate(value.url);
    } else if (isServiceItem(value)) {
      navigate(`service/overview/${value?.serviceTypeId}/${value?.name}`)
    } else {
      navigate(`/tenant/${value?.id}`, {
        state: {
          tenant: value
        }
      })
    }
  };

  const handleInputChange = (event: any, value: string, reason: any) => {
    if (reason === "reset") {
      setInputValue("");
      return;
    } else {
      setInputValue(value);
    }
  };

  //Checks if url is a field in object --> Page
  function isPage(object: any): object is Page {
    return "url" in object;
  }

  //Checks if serviceTypeId is a field in object --> ServiceType
  function isServiceItem(object: any): object is ServiceItem {
    return "serviceItemId" in object;
  }

  //returns string of interface type
  function findType(obj: Page | ServiceItem | Tenant): string {
    if (isPage(obj)) {
      return "Page";
    } else if (isServiceItem(obj)) {
      return "Service";
    } else {
      return "Tenant";
    }
  }


  //TODO Fill in with more pages
  const pages: Page[] = [
    {
      name: "Service Overview",
      url: "service/overview"
    },
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Tenant Overview",
      url: "tenant/overview"
    },
    {
      name: "Reports",
      url:"reports"
    },
    {
      name: "Offers",
      url:"offer/overview"
    }
  ];

  const allOptions: Array<ServiceItem | Page | Tenant> = [];

  pages.forEach((obj) => {
    allOptions.push(obj);
  });
  serviceItems.forEach((obj) => {
    allOptions.push(obj);
  });
  tenants.forEach((obj) => {
    allOptions.push(obj);
  });


  return (
    <Autocomplete
      fullWidth={true}
      classes={{ root: classes.TextField }}
      id="navbar-search"
      options={allOptions}
      inputValue={inputValue}
      onChange={handleSearchClick}
      onInputChange={handleInputChange}
      autoHighlight
      noOptionsText="No page, service or tenant with this name"
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option: ServiceItem | Page | Tenant) =>  option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img loading="lazy" width="20" alt="" />
          <Avatar
            style={{ borderRadius: "0", fontSize: "10px", marginRight: "5px" }}
          >
            {findType(option)}
          </Avatar>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          variant="filled"
          {...params}
          label="Search for a page, service or tenant..."
          InputLabelProps={{
            style: {
              color: "black",
            },
          }}
          inputProps={{
            className: classes.multilineColor,
            ...params.inputProps,
            autoComplete: "off", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
export default NavSearch;
