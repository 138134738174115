import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditRate from "../components/EditComponents/EditRate";
import EditServiceInfo from "../components/EditComponents/EditServiceInfo";
import PageHeader from "../components/PageComponents/PageHeader";
import { useAuth } from "../contexts/AuthContext";
import { ServiceType } from "../models/ServiceType";

interface Props {
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}

/**
 * Container for editing serviceItem
 * @param {ServiceType[]} serviceTypes 
 * @param {SetStateAction} setServiceTypes 
 * @returns EditServiceItemContainer
 */
const EditServiceItemContainer = ({ serviceTypes, setServiceTypes }: Props) => {

  const [tabValue, setTabValue] = useState(0);
  const url = window.location.pathname;
  const urlArr = url.split("/");
  const id = urlArr[3];
  const category = serviceTypes.find(
    (serviceType) => serviceType.serviceTypeId == id
  );
  const item = category?.serviceItems.find(
    (item) => item.serviceItemId == urlArr[4]
  );
  const { tags } = useAuth();
  const navigate = useNavigate();

  //blocks url if the tags is changed and the user dont have the right permissions
  useEffect(() => {
    if (
      tags.findIndex((tag) => tag.scope == "serviceItem:put") == -1 &&
      tags[0].scope != "temp"
    ) {
      navigate(-1);
    }
  }, [tags]);

  return (
    <>
      <PageHeader
        title={"Edit Service"}
        subtitle={item?.name}
        icon={<EditIcon />}
      />
      <Paper elevation={4}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            variant="fullWidth"
            centered={true}
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              value={0}
              label="Edit Rate"
              icon={<AttachMoneyIcon />}
              iconPosition="end"
            ></Tab>
            <Tab
              value={1}
              label="Edit Service Info"
              icon={<ImportContactsIcon />}
              iconPosition="end"
            ></Tab>
          </Tabs>
        </Box>
        {tabValue ? (
          <EditServiceInfo
            setServiceTypes={setServiceTypes}
            serviceTypes={serviceTypes}
          />
        ) : (
          <EditRate
            setServiceTypes={setServiceTypes}
            serviceTypes={serviceTypes}
          />
        )}
      </Paper>
    </>
  );
};

export default EditServiceItemContainer;
