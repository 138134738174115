export enum BillingInterval {
  NEVER = 0,
  INDUVIDUALLY = 1,
  SECOND = 2,
  MINUTE = 3,
  HOUR = 4,
  DAY = 5,
  WEEK = 6,
  MONTH = 7,
  YEAR = 8,
}
