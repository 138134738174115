import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  AlertColor,
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { RateHistory } from "../../models/RateHistory";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import SuccessAlert from "../Dialogs/SuccessAlert";
import RateHistoryCard from "./RateHistoryCard";

interface Props {
  serviceType: ServiceType;
  serviceItem: ServiceItem;
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}

/**
 * Component that displays revisjon form rete changes with filter option on table
 * @param {ServiceType} serviceType Chosen serviceType
 * @param {serviceItem} serviceItem Chosen serviceItem
 * @param {serviceTypes[]} serviceTypes All servicetypes (used in rateHistoryCard)
 * @param {SetStateAction} setServiceTypes Set all servicetypes (used in rateHistoryCard)
 * @returns ServiceItemHistory
 */
const ServiceItemHistory = ({
  serviceItem,
  serviceType,
  setServiceTypes,
  serviceTypes,
}: Props) => {
  const [toDate, setToDate] = React.useState<Date | null>(null);
  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const rateHistories: RateHistory[] = serviceItem.rate.rateHistory;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortOrder, setSortOrder] = useState(true);
  const [success, setSuccess] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();

  const getSortArrow = () => {
    if (sortOrder) return <ArrowDownwardIcon />;
    return <ArrowUpwardIcon />;
  };

  const resetFilters = () => {
    setSortOrder(true);
    setToDate(null);
    setFromDate(null);
  };

  return (
    <>
      <Divider />{" "}
      <Box
        sx={{ display: "flex", margin: "10px", justifyContent: "space-evenly" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <AccessTimeIcon sx={{ marginTop: "15px" }} />
          <Typography
            sx={{ textAlign: "center", paddingTop: "15px" }}
            variant="inherit"
          >
            Filter:
          </Typography>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="From Date"
            inputFormat="MM/dd/yyyy"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />

          <DesktopDatePicker
            label="To Date"
            inputFormat="MM/dd/yyyy"
            value={toDate}
            onChange={(date) => setToDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <IconButton onClick={() => setSortOrder(!sortOrder)}>
          {" "}
          {getSortArrow()} Sort
        </IconButton>
        <IconButton onClick={resetFilters}>
          {<RestartAltIcon />} Reset
        </IconButton>
      </Box>
      <SuccessAlert
        text={
          severity == "success"
            ? "You successfully updated the rate"
            : "Something has gone wrong"
        }
        setOpen={setSuccess}
        open={success}
        severity={severity}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reason</TableCell>
              <TableCell>Billing Inteval</TableCell>
              <TableCell>Cogs</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Price Unit</TableCell>
              <TableCell>Date Changed</TableCell>
              <TableCell>Changed By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ cursor: "pointer" }}>
            {rateHistories
              .filter((rateHistory: RateHistory) => {
                if (fromDate == null) return rateHistory;
                return (
                  new Date(rateHistory.dateChanged).setHours(0, 0, 0, 0) >=
                  fromDate.setHours(0, 0, 0, 0)
                );
              })
              .filter((rateHistory: RateHistory) => {
                if (toDate == null) return rateHistory;
                return (
                  new Date(rateHistory.dateChanged).setHours(0, 0, 0, 0) <=
                  toDate.setHours(0, 0, 0, 0)
                );
              })
              .sort((a: RateHistory, b: RateHistory) => {
                if (sortOrder) {
                  if (a.dateChanged > b.dateChanged) return -1;
                  else if (a.dateChanged < b.dateChanged) return 1;
                  else return 0;
                } else {
                  if (a.dateChanged < b.dateChanged) return -1;
                  else if (a.dateChanged > b.dateChanged) return 1;
                  else return 0;
                }
              })
              .map((rateHistory: RateHistory, i: number) => {
                if (
                  i + 1 > rowsPerPage * (page + 1) - rowsPerPage &&
                  i + 1 <= rowsPerPage * (page + 1)
                ) {
                  return (
                    <RateHistoryCard
                      key={rateHistory.rateHistoryId}
                      rateHistory={rateHistory}
                      serviceType={serviceType}
                      serviceTypes={serviceTypes}
                      setServiceTypes={setServiceTypes}
                      serviceItem={serviceItem}
                      setSeverity={setSeverity}
                      setSuccess={setSuccess}
                    />
                  );
                }
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={
            rateHistories.length > 5
              ? [5, rateHistories.length]
              : [rateHistories.length]
          }
          component="div"
          count={rateHistories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
        />
      </TableContainer>
    </>
  );
};

export default ServiceItemHistory;
