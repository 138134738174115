import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/bilder/AteaPng2.png";
import { protectedResources } from "../authConfig";
import { SignInButton } from "../components/ActionButtons/SignInButton";
import LoginNavbar from "../components/LoginComponents/LoginNavbar";
import { useIsAuth } from "../contexts/IsAuthenticatedContext";

interface Props {
  isDarkMode: boolean;
  updateDarkmode: (bool: boolean) => void;
}

/**
 * Container for login page
 * @param {boolean} isDarkMode Used in LoginNavbar
 * @param {function} updateDarkmode Used in LoginNavbar
 * @returns Login
 */

const Login = ({ isDarkMode, updateDarkmode }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { login } = useIsAuth();
  const navigate = useNavigate();

  const getToken = (): Promise<void> => {
    return instance
      .acquireTokenSilent({
        ...protectedResources,
        account: accounts[0],
      })
      .then((response: any) => {
        const token: string = response.accessToken;
        localStorage.setItem("token", token);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      getToken()
        .then(() => login())
        .then(() => navigate("/"));
      //Used instead of navigate because a first page load is needed because of css
    }
  }, [isAuthenticated]);

  return (
    <>
      <LoginNavbar isDarkMode={isDarkMode} updateDarkmode={updateDarkmode} />

      <Grid
        container
        spacing={0}
        columns={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: "100vh",
          backgroundImage: `url("http://vollark.no/wp-content/uploads/2020/10/Ranheimsvegen-9-exterior-03-1024x684.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid item xs={2}>
          <Paper
            sx={{
              padding: "16px",
              borderRadius: "0",
              background: "rgba(255,255,255, 0.3)",
              backdropFilter: "blur(5px)",
              minWidth: "30vw",
              width: "100%",
              height: "100%",
              margin: "10px  ",
            }}
          >
            <img src={logo} width={150} style={{ marginTop: "10px" }} />
            <Typography
              sx={{ color: "black", marginBottom: "30px" }}
              variant="subtitle1"
            >
              Pricing Calculator
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                textAlign: "center",
                alignContent: "center",
              }}
            >
              <SignInButton />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
