import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import { BillingInterval } from '../../models/BillingInterval';
import { SpecialServiceItem } from '../../models/SpecialServiceItem';


interface Props {
    serviceItem: SpecialServiceItem;
}

/**
 * Component including table with information about the special serviceItem
 * @param {SpecialServiceItem} serviceItem Chosen serviceItem
 * @returns TenantServiceItemInfo
 */
const TenantServiceItemInfo = ({ serviceItem }: Props) => {

    return (<Box>
        <Divider />
        <TableContainer component={Paper}>
            <Table>
                <TableBody>

                    <TableRow>
                        <TableCell>{"Description: "}</TableCell>
                        <TableCell colSpan={1}>{serviceItem.description}</TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell >{"Original Price: "}</TableCell>
                        <TableCell>
                            {
                                serviceItem?.rate.price +
                                " " +
                                serviceItem?.rate.priceUnit
                            }
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{"Discounted Price: "}</TableCell>
                        <TableCell>
                            {
                                serviceItem?.rate.specialPrice +
                                " " +
                                serviceItem?.rate.priceUnit
                            }
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    <TableRow  >
                        <TableCell>{"Discount: "}</TableCell>
                        <TableCell>
                            {
                                ((100 - (serviceItem.rate.specialPrice / serviceItem?.rate.price * 100))).toFixed(2) + "%"
                            }
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    <TableRow  >
                        <TableCell>{"COGS: "}</TableCell>
                        <TableCell>
                            {
                                serviceItem?.rate.cogs +
                                " " +
                                serviceItem.rate.currency
                            }
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    <TableRow  >
                        <TableCell>{"Billing interval: "}</TableCell>
                        <TableCell>
                            {
                                BillingInterval[
                                serviceItem?.rate.billingInterval
                                ]
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    </Box>
    )
}

export default TenantServiceItemInfo;