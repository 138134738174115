import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ConstructionIcon from "@mui/icons-material/Construction";
import {
  Box,
  Collapse,
  IconButton,
  ListItemButton,
  ListItemText,
  Tooltip,
  ListItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import SeriveItemList from "./ServiceItemList";

interface Props {
  inputValue: string;
  serviceItems: ServiceItem[];
  serviceType: ServiceType;
}

/**
 * Component containing list off all underlying serviceItems and button for adding a new serviceItem
 * @constant
 * @param {string} inputvalue User inut form searchbar
 * @param {serviceItem[]} serviceItems List of fitlered serviceItems
 * @param {ServiceType} serviceType The serviceType containing the serviceItems
 * @returns ServiceTypeCard
 */
const ServiceTypeCard = ({ inputValue, serviceItems, serviceType }: Props) => {
  const [openServices, setOpenServices] = useState<boolean>(false);
  const didMount = useRef(false);
  const navigate = useNavigate();
  const { tags } = useAuth();

  /**
   * Expands list if serviceItems list includes the searched serviceItem
   */
  useEffect(() => {
    if (didMount.current && serviceItems.length > 0) {
      setOpenServices(true);
    } else {
      didMount.current = true;
      setOpenServices(false);
    }
  }, [inputValue]);

  return (
    <ListItem
      sx={{ display: "flex", justifyContent: "space-between", padding: "0" }}
    >
      <Box sx={{ width: "100%" }}>
        <ListItemButton
          aria-label="Open service item list"
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-evenly",
          }}
          alignItems="center"
          onClick={() => setOpenServices(!openServices)}
        >
          <ConstructionIcon />
          <ListItemText primary={serviceType.friendlyName}></ListItemText>
          {openServices ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openServices} timeout="auto" unmountOnExit>
          <SeriveItemList
            inputValue={inputValue}
            serviceType={serviceType}
          ></SeriveItemList>
        </Collapse>
      </Box>
        <Tooltip
          title={
            tags.findIndex((tag) => tag.scope == "serviceItem:post") == -1
              ? "No permission"
              : "Add new service"
          }
        >
          <div>
            <IconButton
              aria-label="New service item"
              onClick={() =>
                navigate(`${serviceType.serviceTypeId}/newService`)
              }
              sx={{
                borderRadius: "0",
                borderLeft: "solid 1px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
              disabled={
                tags.findIndex((tag) => tag.scope == "serviceItem:post") == -1
              }
            >
              <AddIcon />
            </IconButton>
          </div>
        </Tooltip>
    </ListItem>
  );
};

export default ServiceTypeCard;
