import { useIsAuthenticated } from "@azure/msal-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { post } from "../ajax";
import { Tag } from "../models/Tag";

interface AuthContextProps {
  tags: Tag[];
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [tags, setTags] = useState<Tag[]>([]);
  const isAuthenticated = useIsAuthenticated();

  const signIn = () => {
    post("/User", {}).then((response: any) => {
      if (response.status == 200) {
        setTags(response.data.tags);
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      signIn();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ tags: tags }}>
      {children}
    </AuthContext.Provider>
  );
}
