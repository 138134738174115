import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  text: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onProceedAction: () => void;
}

/**
 * Dialog displayed to confirm actions done by the user with option to continue or stop.
 * @param {string} text string t display in Dialog
 * @param {boolean} open boolean used to open and close dialog
 * @param {SetStateAction} setOpen Setter for boolean "open"
 * @param {Function} onProceedAction Function to exicute when user chooses to continue action
 * @returns ProceedDialog
 */
const ProceedDialog = ({ open, setOpen, text, onProceedAction }: Props) => {
  const onProceedClick = () => {
    setOpen(false);
    onProceedAction();
  };

  const onRejectClick = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{text}</DialogTitle>
      <DialogActions>
        <Button
          onClick={onProceedClick}
          sx={{ width: "100%" }}
          variant="contained"
          color="success"
        >
          Yes
        </Button>
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          color="error"
          onClick={onRejectClick}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProceedDialog;
