import { useTheme } from "@mui/material/styles";
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { default as React } from 'react';
import { Line } from 'react-chartjs-2';



ChartJS.register(ArcElement, PointElement,
    LineElement, Title, Tooltip, Legend, CategoryScale, LinearScale,
);

type lineDataInterface = {
    label: string,
    data: number[];
    borderColor: string;
    backgroundColor: string;
}

interface Props {
    timeLabels: string[];
    lineData: lineDataInterface[];
}
/**
 * Component for graphs in reports
 * @param {string[]} timeLabels Used for diaplying timeinformation
 * @param {lineDataInterface[]} lineData Used to plot graph
 * @returns ReportLine
 */
const ReportLine = ({ timeLabels, lineData }: Props) => {
    const legendPosition = "right";
    const theme = useTheme();
    const NOK = Intl.NumberFormat('no-NO');


    const lineOptions = {
        plugins: {
            legend: {
                position: legendPosition as "right"
            },
            title: {
                display: true,
                color: `${theme.palette.text.primary}`,
                text: 'Timeline',
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (label: any) { return "NOK " + NOK.format(label) }
                }
            }
        },
        maintainAspectRatio: false
    }

    const lineDatas = {
        labels: timeLabels,
        datasets: lineData,
    }

    return (
        <Line data={lineDatas} options={lineOptions} />
    )
}

export default ReportLine