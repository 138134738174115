import { TextField } from '@mui/material';
import React from 'react';

interface Props {
    inputValue: string,
    setInputValue: (str: string) => void,
}
/**
 * Component used to search for offer in OfferContainer
 * @param {string} inputValue Input form user
 * @param {SetStateAction} setInputvalue Function to change inputValue
 * @returns Searchbar used to filter services in OfferContainer
 */
const OfferSearch = ({ inputValue, setInputValue }: Props) => {

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value)
    };

    return (
        <TextField
            value={inputValue}
            onChange={handleInputChange}
            sx={{ width: "100%", rigth: "0" }}
            variant='filled'
            label="Search for a offer"
            InputLabelProps={{
                style: {
                    color: "black",
                }
            }}
            inputProps={{
                autoComplete: 'off',
            }}
        />
    )
}

export default OfferSearch;
