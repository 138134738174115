import { makeStyles } from "@material-ui/styles";
import SaveIcon from "@mui/icons-material/Save";
import {
  AlertColor,
  Box,
  Button,
  Divider,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { put } from "../../ajax";
import { useForm } from "../../hooks/useFrom";
import { ServiceType } from "../../models/ServiceType";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";
import SuccessAlert from "../Dialogs/SuccessAlert";

const useStyle = makeStyles(() => ({
  root: {
    "& .MuiFormControl-root": {
      margin: "5px",
      marginTop: "10px",
      width: "95%",
    },
    "& .MuiAutocomplete-root": {
      display: "inline",
      flexWrap: "wrap",
    },
  },
}));

interface Props {
  serviceTypes: ServiceType[];
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceType[]>>;
}
/**
 * Component for editing serviceItem informasjon
 * @param {ServiceType[]} serviceTypes Used to get wanted serviceItem and to update serviceType
 * @param {SetStateAction} setServiceTypes Used to update serviceTypes
 * @returns EditServiceInfo
 */
const EditServiceInfo = ({ serviceTypes, setServiceTypes }: Props) => {
  const classes = useStyle();
  const url = window.location.pathname;
  const urlArr = url.split("/");
  const id = urlArr[3];
  const category = serviceTypes.find(
    (serviceType) => serviceType.serviceTypeId == id
  );
  const item = category?.serviceItems.find(
    (item) => item.serviceItemId == urlArr[4]
  );

  let initialState = {
    reason: "",
    name: "",
    description: "",
    serviceTypeId: category?.serviceTypeId,
    price: 0,
    cogs: 0,
    priceUnit: "",
    currency: "",
    billingInterval: 0,
  };

  if (item != undefined) {
    initialState = {
      reason: "",
      name: item.name,
      description: item.description,
      serviceTypeId: category?.serviceTypeId,
      price: item.rate.price,
      cogs: item.rate.cogs,
      priceUnit: item.rate.priceUnit,
      currency: item.rate.currency,
      billingInterval: item.rate.billingInterval,
    };
  }
  // getting the event handlers from our custom hook
  const { onChange, onSubmit, values } = useForm(
    addServiceItemCallback,
    initialState
  );

  // a submit function that will execute upon form submission
  const [open, setOpen] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [errorAlertText, setErrorAlertText] = useState("");

  const onProceedClick = () => {
    const { price, cogs, billingInterval, name } = values;
    values.price = Number(price);
    values.cogs = Number(cogs);
    values.billingInterval = Number(billingInterval);
    values.name = name.trim();
    const putItem = {
      serviceItemId: item?.serviceItemId,
      exivityId: item?.exivityId,
      rateId: item?.rateId,
      name: values.name,
      description: values.description,
    };
    setOpenLoadingModal(true);

    put("/ServiceType/ServiceItem", putItem)
      .then(() => {
        const serviceTypesList: ServiceType[] = serviceTypes;
        const typeIndex = serviceTypesList.findIndex(
          (obj) => obj.serviceTypeId == id
        );
        const itemIndex = serviceTypesList[typeIndex].serviceItems.findIndex(
          (obj) => obj.serviceItemId == urlArr[4]
        );
        serviceTypesList[typeIndex].serviceItems[itemIndex].name = putItem.name;
        serviceTypesList[typeIndex].serviceItems[itemIndex].description =
          putItem.description;
        setServiceTypes(serviceTypesList);
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("success");
      })
      .catch(() => {
        setSuccess(true);
        setOpenLoadingModal(false);
        setSeverity("error");
        setErrorAlertText("Something has gone wrong");
      });
  };

  async function addServiceItemCallback() {
    if (values.name == "" || values.description == "") {
      setSuccess(true);
      setSeverity("error");
      setErrorAlertText(
        "You have to fill out all the fields before editing the service!"
      );
    } else {
      setOpen(true);
      setSuccess(false);
    }
  }

  return (
    <React.Fragment>
      <Divider />
      <form autoComplete="on" onSubmit={onSubmit}>
        <FormControl focused fullWidth={true} className={classes.root}>
          <Box>
            <TextField
              name="ServiceTypeId"
              autoComplete="off"
              variant="outlined"
              label="Category"
              disabled={true}
              value={category?.friendlyName}
            />
            <TextField
              defaultValue={item?.name}
              autoComplete="off"
              name="name"
              onChange={onChange}
              variant="outlined"
              label="Service Name"
            />
            <TextField
              defaultValue={item?.description}
              name="description"
              onChange={onChange}
              multiline
              variant="outlined"
              label="Description"
              autoComplete="off"
            />

            <SuccessAlert
              text={
                severity == "success"
                  ? "You successfully updated the service"
                  : errorAlertText
              }
              setOpen={setSuccess}
              open={success}
              severity={severity}
              buttonText="Go to service"
            />

            <Button
              sx={{ marginTop: "10px" }}
              fullWidth={true}
              color="success"
              variant="contained"
              endIcon={<SaveIcon />}
              type="submit"
            >
              {" "}
              Edit service{" "}
            </Button>
            <LoadingModal
              open={openLoadingModal}
              text="Updating service info"
            />
            <ProceedDialog
              onProceedAction={onProceedClick}
              text="Are you sure you want to edit the service?"
              setOpen={setOpen}
              open={open}
            />
          </Box>
        </FormControl>
      </form>
    </React.Fragment>
  );
};

export default EditServiceInfo;
