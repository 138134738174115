import {
  Box,
  Divider, List, ListItemText
} from "@mui/material";
import React from "react";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import ServiceItemButton from "./ServiceItemButton";

interface Props {
  serviceType: ServiceType;
  inputValue: string;
}

/**
 * Component displying either a list serviceItemButton's or information message
 * @param {ServiceType} serviceType Related serviceType. Used to list serviceItems
 * @param {string} inputValue Passed to ServiceItembutton
 * @returns List of serviceitem names, date for last update and editing button
 */
const ServiceItemList = ({ serviceType, inputValue }: Props) => {
  const getList = (serviceType: ServiceType) => {
    if (serviceType.serviceItems.length != 0) {
      return serviceType.serviceItems.map((serviceItem: ServiceItem, i) => (
        <React.Fragment key={i}>
          <Divider />
          <Box sx={{ display: "flex" }}>
            <ServiceItemButton
              inputValue={inputValue}
              serviceItem={serviceItem}
              serviceType={serviceType}
            />
          </Box>
        </React.Fragment>
      ));
    }
    return (
      <ListItemText primary="This service type does not have any services"></ListItemText>
    );
  };

  return (
    <List
      sx={{
        paddingLeft: "10%",
        /* border: " solid 2px rgba(0, 0, 0, 0.1)", */
        borderTop: "0",
        borderLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
      }}
    >
      {getList(serviceType)}
    </List>
  );
};

export default ServiceItemList;
