import AddIcon from "@mui/icons-material/Add";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Box, Divider,
  ListItemButton, Paper, Tooltip
} from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { get } from "../ajax";
import OfferCard from "../components/OfferComponents/OfferCard";
import OfferSearch from "../components/OfferComponents/OfferSearch";
import PageHeader from "../components/PageComponents/PageHeader";
import { Offer } from "../models/Offer";

/**
 * Container for offer overview
 * @returns OfferContainer
 */
const OfferContainer = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [offers, setOffers] = useState<Offer[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const getOffers = () => {
      get("/Offer").then((response: AxiosResponse) => {
        if (isMounted) {
          setOffers(response.data);
          setMounted(true);
        }
      })
    };
    getOffers();
    return () => {
      isMounted = false;
    };
  }, []);

  const hasInput = (str: string) => {
    return str.toLowerCase().includes(inputValue.toLowerCase());
  };

  const checkOfferName = (offers: Offer[]) => {
    if (inputValue == "") {
      return offers;
    } else {
      return offers.filter((offer: Offer) => {
        return hasInput(offer.name);
      });
    }
  };

  const filterOffers = checkOfferName(offers);

  return (
    <>
      <PageHeader
        title="Offer Overview"
        subtitle="Click on a offer to view more information"
        icon={<LocalOfferIcon />}
      />
      {mounted ? (
        <Box>
          <Paper elevation={4}>
            <OfferSearch
              inputValue={inputValue}
              setInputValue={setInputValue}
            />

            <Tooltip title="Create new offer">
              <ListItemButton
                onClick={() => navigate("/offer/newOffer")}
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "space-evenly",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
              </ListItemButton>
            </Tooltip>
            <Divider />
            {filterOffers.map((offer: Offer) => (
              <OfferCard offer={offer} key={offer.offerId} />
            ))}
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "15vw",
          }}
        >
          <PacmanLoader size={40}></PacmanLoader>
        </Box>
      )}
    </>
  );
};

export default OfferContainer;
