import { makeStyles } from "@material-ui/styles";
import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { User } from "../../models/User";

const useStyles = makeStyles({
    TextField: {
        width: "100%",
    },
    floatingLabelFocusStyle: {
        color: "#202020",
    },
    multilineColor: {
        color: "#202020",
    },
});

interface Props {
    users: User[];
    setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

/**
 * Component used to search for user in Roles
 * @param {User[]} users List of users used inn autocomplete
 * @param {SetStateAction} setSelectedUser Function to set chosen user
 * @returns UserSearch
 */

const UserSearch = ({ users, setSelectedUser }: Props) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState<string>("");


    const handleSearchClick = (event: any, value: User | null) => {
        if(value != null){
            setSelectedUser(value);
        }
    };

    const handleInputChange = (event: any, value: string, reason: any) => {
        if (reason === "reset") {
            setInputValue("");
            return;
        } else {
            setInputValue(value);
        }
    };

    return (
        <Autocomplete
            fullWidth={true}
            classes={{ root: classes.TextField }}
            id="search-select"
            options={users}
            inputValue={inputValue}
            onChange={(handleSearchClick)}
            onInputChange={handleInputChange}
            autoHighlight
            noOptionsText="No user with this email"
            isOptionEqualToValue={(option, value) => option.oid === value.oid}
            getOptionLabel={(option: User) => option.email}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    {option.email}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    variant="filled"
                    {...params}
                    label="Search for a user...(has to be logged in once)"
                    InputLabelProps={{
                        style: {
                            color: "black",
                        },
                    }}
                    inputProps={{
                        className: classes.multilineColor,
                        ...params.inputProps,
                        autoComplete: "off", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}

export default UserSearch