import { useTheme } from "@mui/material/styles";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import React from 'react';
import { Bar } from "react-chartjs-2";
import { backgroundColors } from '../../colors';



interface Props {
    serviceTypeNames: string[];
    profits: number[];
}


ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);
/**
 * Component for bar diagram in dashboard
 * @param {string[]} serviceTypeNames List with serviceType names
 * @param {number[]} profits List with profit for related serviceType
 * @returns DashboardBar
 */
const DashboardBar = ({ serviceTypeNames, profits }: Props) => {
    const theme = useTheme();
    const NOK = Intl.NumberFormat('no-NO')

    const data = {
        labels: serviceTypeNames,
        datasets: [
            {
                label: "Profit in NOK",
                data: profits,
                backgroundColor: backgroundColors,
                borderWidth: 1,
            },
        ],
    };

    const barOptions = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                color: `${theme.palette.text.primary}`,
                text: "Top Services",
            },
            scales: {
                yAxes: [
                    {
                        barPercentage: 0.8,
                    },
                ],
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (label: any) { return "NOK " + NOK.format(label) }
                }
            }
        },
        maintainAspectRatio: false,
    };

    return (
        <Bar data={data} options={barOptions} />
    )
}

export default DashboardBar