import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { ServiceItem } from "../../models/ServiceItem";
import { BillingInterval } from "../../models/BillingInterval";
import React from "react";

interface Props {
  serviceItem: ServiceItem;
}

/**
 * Table with infomation used in ServiceInformation
 * @param {ServiceItem} serviceItem Chosen serviceItem
 * @returns ServiceItemRateTable
 */
const ServiceItemRateTable = ({ serviceItem }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow hover={false}>
            <TableCell>{"Description: "}</TableCell>
            <TableCell>{serviceItem.description}</TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>{"Price: "}</TableCell>
            <TableCell>
              {
                serviceItem?.rate.price +
                  " " +
                  serviceItem.rate.currency +
                  " " +
                  serviceItem?.rate.priceUnit /*TODO vises ikke når null*/
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{"COGS: "}</TableCell>
            <TableCell>
              {serviceItem?.rate.cogs +
                " " +
                serviceItem.rate.currency /*TODO vises ikke når null*/ +
                " " +
                serviceItem?.rate.priceUnit}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{"Billing interval: "}</TableCell>
            <TableCell>
              {
                BillingInterval[
                  serviceItem?.rate.billingInterval
                ] /*TODO vises ikke når null*/
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiceItemRateTable;
