import { AccountInfo } from "@azure/msal-common/dist/account/AccountInfo";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { FormGroup, MenuList, Switch } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../ajax";
import logo from "../../assets/bilder/AteaPng2.png";
import { ServiceItem } from "../../models/ServiceItem";
import { Tenant } from "../../models/Tenant";
import NavSearch from "./NavSearch";
import ProfileMenu from "./ProfileMenu";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

interface Props {
  isDarkMode: boolean;
  updateDarkmode: (bool: boolean) => void;
  showSidebar: boolean;
  setShowSidebar: (bool: boolean) => void;
  accounts: AccountInfo[];
}

/**
 * Component for navigation bar
 * @param {boolean} isDarkMode  Used for theme choice
 * @param {funtion} updateDarkmode Used to settheme choice
 * @param {boolean} showSidebar Used to open and close SideBar
 * @param {function} setShowSidebar Used to open and close SideBar
 * @param {AccountInfo[]} accounts Used to display name of logged in user
 * @returns Navbar
 */
const Navbar = ({
  isDarkMode,
  updateDarkmode,
  showSidebar,
  setShowSidebar,
  accounts,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [serviceItems, setServiceItems] = useState<ServiceItem[]>([]);
  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    let isMounted = true;
    const getTenants = () => {
      get("/Tenant").then((response: AxiosResponse) => {
        if (isMounted) {
          setTenants(response.data);
        }
      });
    };
    const getServiceItems = () => {
      get("/ServiceType/ServiceItem").then((response) => {
        if (isMounted) {
          setServiceItems(response.data);
        }
      });
    };
    getTenants();
    getServiceItems();
    return () => {
      isMounted = false;
    };
  }, []);

  const changeToHomePage = () => {
    navigate("/");
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isMenuOpen) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const menuId = "primary-search-account-menu";

  return (
    <Box sx={{ marginBottom: "10px", flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ zIndex: "1301 !important" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Open or close drawer"
            aria-haspopup="true"
            sx={{ mr: 2 }}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <MenuIcon />{" "}
          </IconButton>
          <IconButton
            aria-label="Go to dashboard"
            onClick={() => {
              changeToHomePage();
            }}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <img src={logo} width={150} alt="Atea logo" />
          </IconButton>
          <NavSearch serviceItems={serviceItems} tenants={tenants} />
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <MenuList sx={{ display: { xs: "none", md: "flex" } }}>
              <MenuItem>
                <FormGroup>
                  <MaterialUISwitch
                    inputProps={{ "aria-label": "Change theme" }}
                    aria-checked={isDarkMode}
                    checked={isDarkMode}
                    onChange={() => updateDarkmode(!isDarkMode)}
                  />
                </FormGroup>
              </MenuItem>
              <MenuItem disabled={true}>
                <Typography variant="body1">
                  Welcome, {accounts[0] && accounts[0].name?.split(" ")[0]}{" "}
                </Typography>
              </MenuItem>
              <IconButton
                size="large"
                edge="end"
                aria-label="Open account info"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle fontSize="large" />
              </IconButton>
            </MenuList>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle fontSize="large" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <ProfileMenu
        graphData={accounts[0]}
        anchorEl={anchorEl}
        setAnchorEl={(element: null | HTMLElement) => setAnchorEl(element)}
      />
    </Box>
  );
};

export default Navbar;
