import PersonIcon from '@mui/icons-material/Person';
import { Box, Paper } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { get } from '../ajax';
import PageHeader from '../components/PageComponents/PageHeader';
import TenantPageServiceItems from '../components/TenantComponents/TenantPageServiceItems';
import { useTenant } from '../contexts/TenantContext';
import { ServiceItem } from '../models/ServiceItem';


/**
 * Container for tenant components
 * @returns TenantPage
 */
const TenantPage = () => {
    const location: any = useLocation();
    const [mounted, setMounted] = useState<boolean>(false);
    const [allServiceItems, setAllServiceItems] = useState<ServiceItem[]>([]);
    const { selectedTenant, setSelectedTenant } = useTenant();


    useEffect(() => {
        let isMounted = true;
        const getServiceItems = () => {
            get("/ServiceType/ServiceItem").then((response: AxiosResponse) => {
                if (isMounted) {
                    setAllServiceItems(response.data);
                    setMounted(true);
                }
            });
        }
        getServiceItems();
        return () => { isMounted = false }
    }, []);


    return (
        <>
            <PageHeader
                title={location.state.tenant.name}
                subtitle={"Services for " + location.state.tenant.name}
                icon={<PersonIcon />}
            />
            {mounted && selectedTenant.id != '' ?
                <Paper elevation={4}>
                    <TenantPageServiceItems
                        tenant={selectedTenant}
                        setTenant={setSelectedTenant}
                        allServiceItems={allServiceItems}
                    />
                </Paper>
                :
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "15vw",
                    }}
                >
                    <PacmanLoader size={40}></PacmanLoader>
                </Box>
            }
        </>
    )
}

export default TenantPage