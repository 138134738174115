import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import {
  AlertColor,
  Box,
  Button,
  Divider,
  IconButton,
  ListSubheader,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { get, put, remove } from "../../ajax";
import { dateConverter } from "../../hooks/DateConverter";
import generatePDF from "../../hooks/GeneratePdf";
import { Offer } from "../../models/Offer";
import { ServiceItem } from "../../models/ServiceItem";
import { ServiceType } from "../../models/ServiceType";
import LoadingModal from "../Dialogs/LoadingModal";
import ProceedDialog from "../Dialogs/ProceedDialog";
import SuccessAlert from "../Dialogs/SuccessAlert";
import PageHeader from "../PageComponents/PageHeader";
import OfferServiceSearch from "./OfferServiceSearch";

interface Props {
  serviceTypes: ServiceType[];
}
/**
 * Component for displaying offer with options to delete and edit offer or generate pdf
 * @param {ServiceType[]} serviceTypes List of all serviceTypes. Used to add new serviceTypes
 * @returns OfferPage
 */

const OfferPage = ({ serviceTypes }: Props) => {
  const location = useLocation();

  const [mounted, setMounted] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [success, setSuccess] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [loadingModalText, setLoadingModalText] = useState("");
  const [errorAlertText, setErrorAlertText] = useState("");
  const [successAlertText, setSuccessAlertText] = useState("");
  let urlArr = location.pathname.split("/");
  let id = urlArr[2];
  const [offer, setOffer] = useState<Offer>();

  useEffect(() => {
    urlArr = location.pathname.split("/");
    id = urlArr[2];
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    const getOffer = () => {
      get(`/Offer/${id}`).then((response: AxiosResponse) => {
        if (isMounted) {
          setOffer(response.data);
          setMounted(true);
        }
      });
    };
    getOffer();
    return () => {
      isMounted = false;
    };
  }, []);

  const onProceedDelete = () => {
    setLoadingModalText("Deleting offer");
    setOpenLoadingModal(true);
    remove(`/Offer/${id}`)
      .then(() => {
        //edit the list
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("success");
      })
      .catch(() => {
        setOpenLoadingModal(false);
        setSuccessAlertText("You successfully deleted the offer");
        setSuccess(true);
        setSeverity("error");
        setErrorAlertText("Something has gone wrong");
      });
  };

  const onProceedSave = () => {
    setLoadingModalText("Saving offer");
    setOpenLoadingModal(true);
    put("/Offer/", offer)
      .then(() => {
        setOpenLoadingModal(false);
        setSuccessAlertText("You successfully saved the offer");
        setSuccess(true);
        setSeverity("success");
      })
      .catch(() => {
        setOpenLoadingModal(false);
        setSuccess(true);
        setSeverity("error");
        setErrorAlertText("Something has gone wrong");
      });
  };

  const handleRemoveItemClick = (param1: ServiceType, param2: ServiceItem) => {
    if (offer != undefined) {
      const serviceIndex = offer.offerItems.findIndex(
        (service) => service.serviceTypeId === param1.serviceTypeId
      );
      const serviceItemIndex = offer.offerItems[
        serviceIndex
      ].serviceItems.findIndex(
        (item) => item.serviceItemId === param2.serviceItemId
      );
      const tempList: ServiceItem[] =
        offer.offerItems[serviceIndex].serviceItems;
      tempList.splice(serviceItemIndex, 1);

      const tempOffer: Offer = offer;
      tempOffer.offerItems[serviceIndex].serviceItems = tempList;
      setOffer({ ...tempOffer });
    }
  };


  const onPriceChange = (
    value: string,
    typeIndex: number,
    itemIndex: number
  ) => {
    if (offer != undefined) {
      const temp: Offer = { ...offer };
      temp.offerItems[typeIndex].serviceItems[itemIndex].rate.price =
        Number(value);
      setOffer(temp);
    }
  };

  const setChosenServiceTypes = (b: ServiceType[]) => {
    if (offer != undefined) {
      const tempoffer = offer;
      tempoffer.offerItems = b;
      setOffer({ ...tempoffer });
    }
  };
  const handleRemoveServiceType = (serviceType: ServiceType) => {
    if (offer != undefined) {
      const tempList = offer.offerItems.filter(
        (service) => service.serviceTypeId !== serviceType.serviceTypeId
      );
      const tempOffer = offer;
      tempOffer.offerItems = tempList;
      setOffer({ ...tempOffer });
    }
  };

  return (
    <>
      {mounted && offer != undefined ? (
        <>
          <PageHeader
            title={offer.name}
            subtitle="Generate pdf or edit offer"
            icon={<LocalOfferIcon />}
          />
          <OfferServiceSearch
            serviceTypes={serviceTypes}
            chosenServiceTypes={offer.offerItems}
            setChosenServiceTypes={setChosenServiceTypes}
          />

          <Box>
            <Paper elevation={4}>
              <Box sx={{ width: "100%" }}>
                {offer?.offerItems.map((serviceType, typeIndex) => {
                  return (
                    <React.Fragment key={serviceType.serviceTypeId}>
                      <ListSubheader
                        color="inherit"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ padding: "10px" }}>
                          {" "}
                          {serviceType.friendlyName}
                        </Typography>
                        <IconButton
                          aria-label={"Remove " + serviceType.friendlyName}
                          onClick={() => handleRemoveServiceType(serviceType)}
                        >
                          <ClearIcon color="error" />
                        </IconButton>
                      </ListSubheader>
                      <Divider />
                      <Table
                        sx={{ width: "100%" }}
                        key={serviceType.serviceTypeId}
                      >
                        <tbody>
                          {serviceType.serviceItems.map(
                            (serviceItem, itemIndex) => {
                              return (
                                <TableRow
                                  sx={{ marginLeft: "10%", width: "20%" }}
                                  key={serviceItem.serviceItemId}
                                >
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    {serviceItem.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    <TextField
                                      autoComplete="off"
                                      label="Price"
                                      variant="filled"
                                      onChange={(e) =>
                                        onPriceChange(
                                          e.target.value,
                                          typeIndex,
                                          itemIndex
                                        )
                                      }
                                      defaultValue={serviceItem.rate.price}
                                    ></TextField>
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    {serviceItem.rate.currency}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    {serviceItem.rate.priceUnit}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "20%" }}
                                    align="center"
                                  >
                                    <IconButton
                                      aria-label={"Remove " + serviceItem.name}
                                      onClick={() => {
                                        handleRemoveItemClick(
                                          serviceType,
                                          serviceItem
                                        );
                                      }}
                                    >
                                      <ClearIcon color="error" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  );
                })}
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "1%",
                }}
              >
                <Typography>
                  Last updated: ( {dateConverter(offer.lastUpdated)} ){" "}
                </Typography>
                <Typography> Created by: {offer.creator} </Typography>
              </Box>
              <Divider />

              <SuccessAlert
                text={severity == "success" ? successAlertText : errorAlertText}
                setOpen={setSuccess}
                open={success}
                severity={severity}
              />
              <Box sx={{ display: "flex", justifyContent: "evenly" }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  sx={{ borderRadius: "0" }}
                  endIcon={<DeleteIcon />}
                  onClick={() => setOpenDelete(true)}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  fullWidth
                  sx={{ borderRadius: "0" }}
                  endIcon={<PictureAsPdfIcon />}
                  onClick={() =>
                    generatePDF(offer.offerItems, offer.name, offer.creator)
                  }
                >
                  Generate PDF
                </Button>

                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ borderRadius: "0" }}
                  endIcon={<SaveIcon />}
                  onClick={() => setOpenSave(true)}
                >
                  Save
                </Button>

                <LoadingModal open={openLoadingModal} text={loadingModalText} />

                <ProceedDialog
                  onProceedAction={onProceedDelete}
                  text="Are you sure you want to delete the offer?"
                  setOpen={setOpenDelete}
                  open={openDelete}
                />
                <ProceedDialog
                  onProceedAction={onProceedSave}
                  text="Are you sure you want to create the offer?"
                  setOpen={setOpenSave}
                  open={openSave}
                />
              </Box>
            </Paper>
          </Box>
        </>
      ) : (
        <>
          <PageHeader
            title="Loading Offer"
            subtitle=""
            icon={<HourglassBottomIcon />}
          ></PageHeader>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "15vw",
            }}
          >
            <PacmanLoader size={40}></PacmanLoader>
          </Box>
        </>
      )}
    </>
  );
};

export default OfferPage;
