import {
  Alert,
  AlertColor,
  Box,
  Button,
  Collapse,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  severity: AlertColor | undefined;
  buttonText?: string;
}

/**
 * Feedback alert after communication wit api
 * @param {boolean} open Boolean used to open and close alert
 * @param {SetStateAction} setOpen Setter for "open"
 * @param {string} text Text to display in alert
 * @param {AlertColor} severity Color of alert
 * @param {string} buttonText Optional button text used to navigate one step back on in
 * @returns SuccessAlert
 */

const SuccessAlert = ({ open, setOpen, text, severity, buttonText }: Props) => {
  const navigate = useNavigate();

  //Removes alert after 5 seconds
  useEffect(() => {
      if(open == true){
        setTimeout( () => {
          setOpen(false);
      }, 5000);
      }
  }, [open])

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <>
              {
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => navigate(-1)}
                >
                  {buttonText}
                </Button>
              }

              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          }
          sx={{ mb: 2 }}
        >
          {text}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default SuccessAlert;
