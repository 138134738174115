import { AlertColor, Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from 'react';
import { post } from '../../ajax';
import { errorConverter } from '../../hooks/errorConverter';
import { BillingInterval } from '../../models/BillingInterval';
import { ServiceItem } from '../../models/ServiceItem';
import { SpecialRate } from '../../models/SpecialRate';
import { SpecialServiceItem } from '../../models/SpecialServiceItem';
import { Tenant } from '../../models/Tenant';
import LoadingModal from "../Dialogs/LoadingModal";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    filteredServiceItems: ServiceItem[];
    tenant: Tenant;
    setTenant: React.Dispatch<React.SetStateAction<Tenant>>;
    open: boolean;
    setOpen: (bool: boolean) => void;
    title: string;
    setSuccessText: React.Dispatch<React.SetStateAction<string>>;
    setErrorAlertText: React.Dispatch<React.SetStateAction<string>>;
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setSeverity: React.Dispatch<React.SetStateAction<AlertColor | undefined>>;
}

/**
 * //Dialog to edit or add a special price for a tenant
 */
const NewSpecialPriceDialog = ({ filteredServiceItems, tenant, setTenant, open, setOpen, title, setSuccessText, setErrorAlertText, setSuccess, setSeverity }: Props) => {
    const [serviceItem, setServiceItem] = useState<SpecialServiceItem | undefined>();
    const [name, setName] = useState<string>("");
    const [discount, setDiscount] = useState<number>(0);
    const [specialPrice, setSpecialPrice] = useState<number>(0);
    const [viewDiscount, setViewDiscount] = useState<string>("");
    const [viewSpecialPrice, setViewSpecialPrice] = useState<string>("");
    const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);

    useEffect(() => {
        if (serviceItem != undefined) {
            setDiscount(0);
            setSpecialPrice(serviceItem?.rate.price);
            setViewDiscount("0")
            setViewSpecialPrice(serviceItem?.rate.price.toString());
        }
    }, [serviceItem]);

    useEffect(() => {
        let isMounted = true;
        const updateServiceItem = () => {
            if (isMounted) {
                const index = filteredServiceItems.findIndex(item => item.name == name);
                setServiceItem(filteredServiceItems[index] as SpecialServiceItem);
            }
        }
        updateServiceItem();
        return () => { isMounted = false; }
    }, [name]);

    const onProceedAction = () => {
        setOpenLoadingModal(true);
        const item = {
            rateId: serviceItem?.rate.rateId,
            specialPrice: specialPrice,
            specialCogs: serviceItem?.rate.cogs,
            tenantId: tenant.id,
            serviceItemExivityId: serviceItem?.exivityId
        }

        post("/SpecialRate", item)
            .then((response) => {
                const newTenant = tenant;
                if (serviceItem != undefined) {
                    const newRate: SpecialRate = {
                        billingInterval: serviceItem?.rate.billingInterval,
                        cogs: serviceItem?.rate.cogs,
                        currency: serviceItem?.rate.currency,
                        price: serviceItem?.rate.price,
                        priceUnit: serviceItem?.rate.priceUnit,
                        rateHistory: serviceItem?.rate.rateHistory,
                        rateId: serviceItem?.rateId,
                        specialPrice: response.data.specialPrice,
                        specialRateId: response.data.specialRateId,
                        specialCogs: serviceItem?.rate.cogs,
                        exivityId: response.data.exivityId
                    }
                    const newServiceItem: SpecialServiceItem = {
                        serviceItemId: serviceItem?.serviceItemId,
                        name: serviceItem?.name,
                        description: serviceItem?.description,
                        rateId: serviceItem?.rateId,
                        rate: newRate,
                        serviceTypeId: serviceItem?.serviceTypeId,
                        exivityId: serviceItem?.exivityId
                    }
                    const specialServiceItemsList = newTenant.specialServiceItems.concat(newServiceItem)
                    newTenant.specialServiceItems = specialServiceItemsList;
                    setTenant(newTenant)
                }
            }).then(() => {
                setServiceItem(undefined);
                setName("");
                setViewDiscount("");
                setViewSpecialPrice("");
                setDiscount(0);
                setSpecialPrice(0);
                setOpenLoadingModal(false);
                setSuccess(true);
                setSeverity("success");
                setSuccessText("Successfully added new special price");
            }).catch((error) => {
                setOpenLoadingModal(false);
                setSuccess(true);
                setSeverity("error");
                setErrorAlertText(errorConverter(error.response.data));
            });
    }

    const onProceedClick = () => {
        onProceedAction();
        setOpen(false);
    };

    const onRejectClick = () => {
        setServiceItem(undefined);
        setName("");
        setViewDiscount("");
        setViewSpecialPrice("");
        setDiscount(0);
        setSpecialPrice(0);
        setOpen(false);
    };

    const handleChange = (event: SelectChangeEvent<typeof name>) => {
        setName(event.target.value)
    };

    const handleDiscountChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setViewDiscount(e.target.value);
        setDiscount(Number(e.target.value));
        if (serviceItem !== undefined) {
            const disc = (100 - Number(e.target.value)) / 100
            const oldPrice = serviceItem.rate.price;
            const newPrice = disc * oldPrice;
            setSpecialPrice(Number((newPrice).toFixed(3)))
            setViewSpecialPrice((newPrice).toFixed(3))
        }
    }

    const handleNewPriceChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setViewSpecialPrice(e.target.value);
        setSpecialPrice(Number(e.target.value))
        if (serviceItem !== undefined) {
            setDiscount(Number(((1 - Number(e.target.value) / serviceItem.rate.price) * 100).toFixed(2)))
            setViewDiscount(((1 - Number(e.target.value) / serviceItem.rate.price) * 100).toFixed(2))
        }
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                <DialogTitle>{title}</DialogTitle>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel>Select a service</InputLabel>
                    <Select
                        defaultValue=""
                        value={name}
                        onChange={handleChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                    >
                        {filteredServiceItems && filteredServiceItems.map((item) => (
                            <MenuItem
                                key={item.serviceItemId}
                                value={item.name.toString()}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Divider />
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>{"Description: "}</TableCell>
                                <TableCell colSpan={1}>{serviceItem?.description}</TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell >{"Original Price: "}</TableCell>
                                <TableCell>
                                    {
                                        serviceItem !== undefined &&
                                        serviceItem?.rate.price +
                                        " " +
                                        serviceItem?.rate.priceUnit /*TODO vises ikke når null*/
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{"Billing interval: "}</TableCell>
                                <TableCell>
                                    {
                                        serviceItem !== undefined && BillingInterval[
                                        serviceItem?.rate.billingInterval
                                        ]
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{"COGS: "}</TableCell>
                                <TableCell>
                                    {
                                        serviceItem !== undefined &&
                                        serviceItem?.rate.cogs +
                                        " " +
                                        serviceItem?.rate.currency /*TODO vises ikke når null*/
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{"Discount: "}</TableCell>
                                <TableCell>
                                    <TextField
                                        onChange={handleDiscountChange} value={viewDiscount} error={discount > 100 || discount < 0} disabled={serviceItem === undefined} inputProps={{
                                            style: { textAlign: 'right' }, maxLength: 5
                                        }} /> %
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{"New price: "}</TableCell>
                                <TableCell>
                                    <TextField onChange={handleNewPriceChange} value={viewSpecialPrice} disabled={serviceItem === undefined} inputProps={{
                                        style: { textAlign: "right" }
                                    }} />
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <Button
                        onClick={onProceedClick}
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="success"
                        disabled={serviceItem === undefined || specialPrice < 0 || isNaN(specialPrice)}
                    >
                        Add
                    </Button>
                    <Button
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="error"
                        onClick={onRejectClick}
                    >
                        Exit
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingModal text="Adding new Special Price" open={openLoadingModal} />
        </>
    )
}

export default NewSpecialPriceDialog;